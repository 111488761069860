import React from 'react'
import Header from '../../UiComponent/Header'
import { defaultLabelColors } from '../../utils/constants'
import "./navbar.css"

function PracticePage(props:any) {
  return (
  <></>
  )
}

export default PracticePage
