import React from "react";
import CustomFooter from "../../UiComponent/CustomFooter";
import { AppStoreFR, FrankIcon } from "../../assets/images/FrankIcons";
import { Grid } from "@mui/material";
import NewHeader from "../../UiComponent/NewHeader";
import { defaultLabelColors, spacing } from "../../utils/constants";
import InputField from "../../UiComponent/TextFeild";
import { LocalOffer, PushPin, Search } from "@mui/icons-material";
import marketplace_banner_img from "../../assets/images/FrankIcons/services-icon-1.png";
import marketplace_banner_img_two from "../../assets/images/ec20a7fa2b94b62d2a3b0e9fcbfec8543d68719d.png";

function MarketPlace(props: any) {
  const { classes } = props;
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {}
  );

  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;

      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  return (
    <div className="web-screen">
      <div style={{ background: "#f9c9b3" }}>
        <Grid
          container
          spacing={2}
          className="display-flex"
          style={{
            background: "#F4C9B3",
            paddingLeft: "40px",
            paddingRight: "40px",
            paddingTop: "30px",
          }}
        >
          <Grid xs={12} item style={{ zIndex: 99 }}>
            <NewHeader />
          </Grid>
        </Grid>

        <div style={{ padding: "0px 50px 0px 50px" }}>
          <Grid container spacing={spacing.spacing2}>
            <Grid xs={12} item>
              <p className="page-title-one">Marché de Frank</p>
            </Grid>
            <Grid xs={4} item style={{ display: "flex" }}>
              <InputField
                input_type={"text"}
                input_value={state?.searchedText ?? ""}
                onchange={onInputChange}
                startIcon={<Search />}
                placeHolder={"Rechercher votre produit"}
                input_name={"searchedText"}
                input_style={{
                  background: "#fff",
                  borderRadius: "16px",
                  border: "none",
                  fontSize: `64px !important`,
                  width: "100%",
                }}
              />
            </Grid>
            <Grid xs={3} item style={{ display: "flex" }}>
              <InputField
                input_type={"text"}
                input_value={state?.location ?? ""}
                onchange={onInputChange}
                startIcon={<PushPin />}
                placeHolder={"Localisation"}
                input_name={"location"}
                input_style={{
                  background: "#fff",
                  borderRadius: "16px",
                  border: "none",
                  fontSize: `64px !important`,
                  width: "100%",
                }}
              />
            </Grid>
            <Grid xs={3} item style={{ display: "flex" }}>
              <InputField
                input_type={"text"}
                input_value={state?.prix ?? ""}
                onchange={onInputChange}
                startIcon={<LocalOffer />}
                placeHolder={"Prix"}
                input_name={"prix"}
                input_style={{
                  background: "#fff",
                  borderRadius: "16px",
                  border: "none",
                  fontSize: `64px !important`,
                  width: "100%",
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={spacing.spacing2}>
            <Grid xs={12} item>
              <p className="page-title-two">
                Nos catégories les plus populaires
              </p>
            </Grid>
            <Grid xs={12} item className="display-flex">
              <p
                style={{
                  textAlign: "center",
                  color: "grey",
                  fontSize: "42px",
                  fontFamily: "Raleway-Variable",
                }}
              >
                No Categories Found
              </p>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{display:'flex',flexDirection:'row',gap:40,justifyContent:'center'}}>
            <Grid
              xs={12}
              md={5.5}
              item
              style={{
                background: defaultLabelColors.main_orange,
                borderRadius: "32px",
                padding: "32px",
                overflow: "hidden",
                width:'98%'
              }}
            >
              <div style={{ display: "flex", height: "120px" }}>
                <div
                  style={{
                    flex: 1,
                    fontSize: "26px",
                    fontFamily: "Raleway-Variable",
                    fontWeight: "700",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Lorem ipsum <br /> dolar sit amet.
                </div>
                <div>
                  <img src={marketplace_banner_img} alt="" style={{}} />
                </div>
              </div>
            </Grid>
            <Grid
              xs={12}
              md={5.5}
              item
              style={{
                background: defaultLabelColors.main_blue,
                borderRadius: "32px",
                padding: "32px",
                overflow: "hidden",
                width:'98%',
              }}
            >
              <div style={{ display: "flex", height: "120px" }}>
                <div
                  style={{
                    flex: 1,
                    fontSize: "26px",
                    fontFamily: "Raleway-Variable",
                    fontWeight: "700",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Lorem ipsum <br />
                  dolar sit amet.
                </div>
                <div>
                  <img
                    src={marketplace_banner_img_two}
                    alt=""
                    style={{
                      height: "400px",
                      width: "400px",
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid xs={12} item>
              <p className="page-title-two">Derniers produits mis en ligne</p>
            </Grid>
            <Grid xs={12} sm={6} md={4} lg={3} item></Grid>
          </Grid>


          <Grid container spacing={2}>
            <Grid xs={12} item>
              <p className="page-title-two">Les boutiques tendances</p>
            </Grid>
            <Grid xs={12} sm={6} md={4} lg={3} item></Grid>
          </Grid>


          <Grid container spacing={2}>
            <Grid xs={12} item>
              <p className="page-title-two">Une autre catégorie</p>
            </Grid>
            <Grid xs={12} sm={6} md={4} lg={3} item></Grid>
          </Grid>
        </div>
      </div>

      <div
        className="main-container"
        style={{ background: "#fff", marginTop: "100px" }}
      >
        <CustomFooter />
      </div>
    </div>
  );
}

export default MarketPlace;
