import { Autocomplete, LoadScript } from "@react-google-maps/api";
import React, { useRef } from "react";

interface GoogleAutocompleteFieldProps {
  apiKey: string; // API key is passed as a prop for flexibility
  placeholder?: string; // Optional placeholder for the input field
  onPlaceSelected: (place: google.maps.places.PlaceResult | null) => void; // Callback function for when a place is selected
  styles?: React.CSSProperties; // Optional styles for the input field
}

const libraries: ('places')[] = ['places'];

const GoogleAutocompleteField: React.FC<GoogleAutocompleteFieldProps> = ({
  apiKey,
  placeholder = "Search for a location", // Default placeholder
  onPlaceSelected,
  styles = { width: '100%', padding: '10px', margin: '10px 0' }, // Default styles
}) => {
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place && place.geometry) {
        onPlaceSelected(place); // Call the callback with the selected place
      } else {
        onPlaceSelected(null); // Call the callback with null if no place details are available
      }
    }
  };

  return (
    <LoadScript googleMapsApiKey={apiKey} libraries={libraries}>
      <Autocomplete
      
        onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
        onPlaceChanged={handlePlaceChanged}
      >
        <input
          ref={inputRef}
          type="text"
          placeholder={placeholder}
          style={styles}
        />
      </Autocomplete>
    </LoadScript>
  );
};

export default GoogleAutocompleteField;