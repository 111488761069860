import PropTypes from "prop-types";
import LandingPage from "../views/LandingPage";
import LoginPage from "../views/Auth";
import SignUp from "../views/SignUp";
import Profile from "../views/Profile";
import Blogs from "../views/Blogs";
import SendParcel from "../views/SendParcel";
import SendPackage from "../views/SendPackage";
import TranspoprterUnColis from "../views/TransporterUnColis";
import PracticePage from "../views/PracticePage";
import CheckoutPackage from "../views/CheckoutPackage";
import MarketPlace from "../views/MarketPlace";
 
// ==============================|| MAIN ROUTING ||============================== //

export function MainAppRoutes({ classes }: any) {
  const MainRoutesBefore = {
    path: "/",
    // element: <LandingPage  />,
    children: [
      {
        path: "",
        element: <LandingPage classes={classes} />,
      },
      {
        path: "login",
        element: <LoginPage classes={classes} />,
      },
      {
        path: "sign-up",
        element: <SignUp classes={classes} />,
      },
      {
        path: "profile",
        element: <Profile classes={classes} />,
      },
      {
        path: "blogs",
        element: <Blogs classes={classes} />,
      },
      {
        path: "send-parcel",
        element: <SendParcel classes={classes} />,
      },
      {
        path: "send-package",
        element: <SendPackage classes={classes} />,
      },
      {
        path: "transporter-un-colis",
        element: <TranspoprterUnColis classes={classes} />,
      },
      {
        path: "practice",
        element: <PracticePage classes={classes} />,
      },
      {
        path: "checkout-package",
        element: <CheckoutPackage classes={classes} />,
      },
      {
        path: "marketplace",
        element: <MarketPlace classes={classes} />,
      },
      
    ],
  };

  return MainRoutesBefore;
}

MainAppRoutes.propTypes = {
  classes: PropTypes.object,
};
