import React from "react";
import PropTypes from "prop-types";
import { customFlagList } from "./FlagList";
import default_icon from "./country-flags/default_flag.png";
// import DraggableDialog from "ui-components/DraggableDialog";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import "./InputFlag.css";
import classNames from "classnames";
import CloseIcon from "@mui/icons-material/Close";

function FlagInputField(props: any) {
  const { label, error, isDisabled, disabled, name, value, classes, required,inputStyle,divStyle } =
    props;

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      openList: false,
      open: false,
      countryList: customFlagList,
      search: "",
      label: "",
      Divider: true,
      countryIcon: default_icon,
      countryCode: "", //this.props.countryCode,
      isFlagEnable: false, // this.props.isWhatsappEnabled,
      countryName: "",
    }
  );
  React.useEffect(() => {
    getCountryByCountryCode(props.countryCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.countryCode]);
  const getCountryByCountryCode = (code: any) => {
    try {
      const selectedCountry = state.countryList?.filter(
        (item: any) => item.dial_code === code
      );
      setState({
        countryIcon: selectedCountry[0].flag,
        countryCode: selectedCountry[0].dial_code,
        countryName: selectedCountry[0].name,
      });
    } catch (error) {}
  };
  const handleOpenList = () => {
    setState({
      open: !state.open,
      countryList: customFlagList,
    });
  };
  const filterCountries = (e: any) => {
    const filter = customFlagList?.filter(
      (phone) =>
        phone.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 ||
        phone.dial_code.indexOf(e.target.value) !== -1 ||
        phone.code.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
    );
    setState({
      countryList: filter,
    });
  };
  const onClickItem = (country: any) => {
    try {
      setState({
        countryIcon: country.flag,
        countryCode: country.dial_code,
        countryList: customFlagList,
        countryName: country.name,
      });
      handleOpenList();
      props.handleChangeMobileCode(
        country.dial_code as any,
        props.codeName as any
      );
    } catch (error) {}
  };
  return (
    <div>
       

      <div
        style={divStyle}
        className={`mobile-container ${
          error ? "mobile-input-background-red" : "mobile-input-background"
        } ${isDisabled ? "mobile-input-background-disabled" : ""}`}
      >
        <div
          style={{ fontFamily: "Raleway-Variable" }}
          className={disabled ? "" : "cursor-pointer-caret"}
          onClick={() => {
            if (disabled) {
              return;
            } else {
              if (isDisabled) {
                return;
              }
              handleOpenList();
            }
          }}
        >
          <img
            alt=""
            title="Click Icon to see Country List"
            className={
              state?.countryIcon === default_icon
                ? ""
                : "mobile-container-caret  mobile-flag-icon"
            }
            src={state?.countryIcon?.default || state.countryIcon}
            style={{
              height: 40,
              objectFit: "contain",
              paddingLeft: 5,
            }}
          />
        </div>

        <div>
          <span
            className="mobile-container-countrycode"
            style={{
              color: "#212121",
              fontFamily: "Raleway-Variable",
              fontSize: 18,
              fontWeight: "500",
              lineHeight: "20px",
            }}
          >
            {state.countryCode}
          </span>
        </div>

        {!state.countryCode ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <KeyboardArrowDownIcon
              onClick={() => {
                handleOpenList();
              }}
              style={{ color: "#444444", cursor: "pointer", fontSize: 25 }}
            />
          </div>
        ) : null}

        <div
          style={{ width: "2px", height: "38px", backgroundColor: "#bdbdbd" }}
        ></div>

        <div
          className="mobile-container-input-container"
          style={{ display: "flex" }}
        >
          <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <input
              maxLength={15}
              type="tel"
              className="mobile-container-input"
              style={inputStyle}
              value={value}
              name={name}
              disabled={isDisabled ? true : disabled}
              onChange={(event: any) => {
                props.handleChangeMobile(
                  event,
                  state.countryCode,
                  props.codeName
                );
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </form>
        </div>
      </div>

      <Dialog maxWidth="xs" open={state.open} onClose={handleOpenList}>
        <DialogTitle
          className={classNames(
            classes?.display_flex_baseline,
            classes?.modal_background
          )}
          style={{background:"#FCE6DD"}}
        >
          <div
            className={classNames(
              classes?.display_flex_baseline,
              classes?.modal_backgroundd
            )}
            style={{display:'flex'}}
          >
            <div
              style={{
                flex: 1,
                fontFamily:'Raleway-Variable'
              }}
              className={`${classes?.modal_title || ""}`}
            >
              Select Phone Dialing Code
            </div>
            <div>
              <CloseIcon
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  marginLeft: "100",
                  color:'#ee6931'
                }}
                onClick={handleOpenList}
              />
            </div>
          </div>
        </DialogTitle>

        <DialogContent>
          <div
            style={{
              padding: "10px 0px 0px 0px",
            }}
          >
            <TextField
              fullWidth
              size="medium"
              autoFocus
              InputLabelProps={{ shrink: true }}
              variant="standard"
              placeholder="Search..."
              onChange={(e) => filterCountries(e)}
              InputProps={{
                style: { fontSize: 16, width: "100%" },

                startAdornment: (
                  <InputAdornment position="start">
                    <Search className={classes?.color_c} />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: 15,
                fontSize: 16,
                className: classes?.search_input,
              }}
            />
          </div>
        </DialogContent>

        <DialogContent className="mobile-list-width">
          <div className="mobile-list">
            {state.countryList?.map((_country: any, index: any) => {
              return (
                <div key={`${_country.title}-${index}`}>
                  {_country.title ? (
                    <div
                      style={{
                        color: "#3026f1",
                        padding: 10,
                        fontSize: 16,
                        background: "#f9c9b3",
                        fontWeight: "normal",
                        lineHeight: "normal",
                        paddingLeft: 20,
                        letterSpacing: "normal",
                        textTransform: "capitalize",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {_country.name}
                    </div>
                  ) : (
                    <div
                      className="flag-item"
                      onClick={() => onClickItem(_country)}
                    >
                      <Grid
                        container
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Grid xs={1} item>
                          <img
                            className="mobile-flag-icon"
                            src={_country.flag?.default || _country.flag}
                            title={_country.name}
                            alt="Flag Icon"
                          />
                        </Grid>
                        <Grid
                          xs={8}
                          item
                          style={{ paddingLeft: 10,fontFamily:'Raleway-Variable' }}
                          className={`${classes?.text_1}`}
                        >
                          {_country.name}
                        </Grid>
                        <Grid
                          xs={3}
                          item
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            fontFamily:'Raleway-Variable'
                          }}
                          className={`${classes?.text_1}`}
                        >
                          {_country.dial_code}
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

FlagInputField.defaultProps = {
  label: " ",
  required: true,
  classes: {},
  name: "",
  codeName: "",
  value: "",
  countryCode: "",
  handleChangeMobileCode: {},
  handleChangeMobile: {},
  enableFlagInput: "",
  iso: "",
  isDisabled: false,
  inputStyle:{
    fontFamily: "Raleway-Variable",
    fontSize: 18,
    fontWeight: "500",
    lineHeight: "10px",
    letterSpacing: "normal",
    color: "#212121",
    background: "#fff",
    width: "100%",
    borderRadius: "16px",
    border: "none",
    
  },
  divStyle:{
    background: "#fff",
    width: "100%",
    borderRadius: "16px",
    border: "none",
    padding:'10px'
  }
};

FlagInputField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  classes: PropTypes.any,
  name: PropTypes.any,
  codeName: PropTypes.any,
  value: PropTypes.any,
  countryCode: PropTypes.any,
  handleChangeMobileCode: PropTypes.func,
  handleChangeMobile: PropTypes.func,
  enableFlagInput: PropTypes.func,
  iso: PropTypes.any,
  isDisabled: PropTypes.bool,
  divStyle:PropTypes.any,
  inputStyle:PropTypes.any
};

export default FlagInputField;
