import React, { useState } from "react";
import {
  FrankIcon,
  LanguageOrangeIcon,
  MailOrangeIcon,
  SearchOrangeIcon,
  UserOrangeIcon,
} from "../../assets/images/FrankIcons";
import { useNavigate } from "react-router-dom";
import englishIcon from "../../assets/images/FrankIcons/en-icon.png";
import frenchIcon from "../../assets/images/FrankIcons/fr-icon.png";

import {
  ArrowDropDown,
  KeyboardArrowDown,
  Logout,
  PersonAdd,
  PersonOutlined,
  Settings,
  Translate,
} from "@mui/icons-material";
import {
  clearAllDataFromLocalStorage,
  getDataFromLocalStorage,
} from "../../utils/LocalStore.util";
import { Avatar, Divider, ListItemIcon, Menu, MenuItem } from "@mui/material";
import Toast from "../Toast/Toast";
import { defaultLabelColors } from "../../utils/constants";

function Header() {
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
    currentLanguage:"FR"
    }
  );

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let userDetails = getDataFromLocalStorage("user");
  const handleLogout = () => {
    try {
      clearAllDataFromLocalStorage();
      navigate("/");
      Toast.showSuccess("Logout Succesfully");
    } catch (error) {}
  };

  return (
    <>
      <div className="header">
        <div className="frame">
          <div className="left">
            <a href="/" style={{ marginBottom: "-10px",marginLeft:15 }}>
              <FrankIcon fill={"#ee6931"} height={55} />
            </a>
            <div
              className="items"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="menu-component menu-container"
                style={{ cursor: "pointer" }}
              >
                <span
                  className="particulier"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Particulier{" "}
                  <KeyboardArrowDown
                    style={{ fontSize: "24px", fontWeight: "lighter" }}
                  />
                </span>

                <div className="dropdown">
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      navigate("/send-package");
                    }}
                  >
                    Envoyer un colis
                  </div>
                  <div className="dropdown-item">Suivre mon colis</div>
                  <div className="dropdown-item">Devenir transporteur</div>
                </div>
              </div>

              <div
                className="menu-component menu-container"
                style={{ cursor: "pointer" }}
              >
                <span
                  className="particulier"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Transporteur{" "}
                  <KeyboardArrowDown
                    style={{ fontSize: "24px", fontWeight: "lighter" }}
                  />{" "}
                </span>

                <div className="dropdown">
                  <div className="dropdown-item">Devenir Transporteur</div>
                  <div className="dropdown-item">
                    Voir les annonces pour une livraison
                  </div>
                </div>
              </div>

              <div
                className="menu-component menu-container"
                style={{ cursor: "pointer" }}
              >
                <span
                  className="particulier"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  Déménagement{" "}
                  <KeyboardArrowDown
                    style={{ fontSize: "24px", fontWeight: "lighter" }}
                  />{" "}
                </span>

                <div className="dropdown">
                  <div className="dropdown-item"> Déménageur particulier</div>
                  <div className="dropdown-item">Déménageur professionnel</div>
                </div>
              </div>
              <div
                className="menu-component menu-container"
                style={{ cursor: "pointer", marginLeft: "20px" }}
              >
                <span
                  className="particulier"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Market place
                </span>
              </div>

              <div
                className="menu-component menu-container"
                style={{ cursor: "pointer" }}
              >
                <span
                  className="particulier"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Stockage colis{" "}
                  <KeyboardArrowDown
                    style={{ fontSize: "24px", fontWeight: "lighter" }}
                  />
                </span>

                <div className="dropdown">
                  <div className="dropdown-item">
                    {" "}
                    Trouver un point relais particulier
                  </div>
                  <div className="dropdown-item">
                    Proposer un espace de point relais
                  </div>
                  <div className="dropdown-item">Consigne bagage</div>
                </div>
              </div>
              <div
                className="menu-component menu-container"
                style={{ cursor: "pointer", marginLeft: "20px" }}
              >
                <span
                  className="particulier"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Suivre mon colis
                </span>
              </div>
            </div>
          </div>
          <div className="right">
            {userDetails ? (
              <div className="tracker" style={{ cursor: "pointer" }}>
                <div className="line-mail">
                  <MailOrangeIcon />
                </div>
              </div>
            ) : null}
 

            {userDetails ? (
              <div
                className="compte"
                style={{ cursor: "pointer", position: "relative" }}
              >
                <div className="user" style={{}}>
                  <div onClick={handleClick}>
                    <UserOrangeIcon />
                  </div>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      width: "200px",
                      borderRadius: "12px",
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    className="dropdown-item"
                    style={{ fontFamily: "Raleway-Variable" }}
                    onClick={() => {
                      navigate("/profile");
                    }}
                  >
                    <Avatar /> Profil
                  </MenuItem>

                  <Divider />

                  <MenuItem
                    className="dropdown-item"
                    style={{ fontFamily: "Raleway-Variable" }}
                    onClick={handleClose}
                  >
                    <ListItemIcon>
                      <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                  </MenuItem>
                  <MenuItem
                    className="dropdown-item"
                    style={{ fontFamily: "Raleway-Variable" }}
                    onClick={handleLogout}
                  >
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            ) : (
              <div style={{display:'flex',justifyContent:'center',alignItems:'center',border:"1px solid #F4C7B8 ",borderRadius:4,padding:5,color:"#ee6931",gap:10}}>
                <div
                  onClick={() => {
                    navigate("/sign-up");
                  }}
                  style={{display:'flex',justifyContent:'center',alignItems:'center'}}

                >
                  <span
                    className="particulier"
                    style={{
                      cursor: "pointer",
                      textAlign:'center'
                    }}
                  >
                    S'inscrire
                  </span>
                </div>
<div style={{width:"2px",height:"10px",background:"#F4C7B8"}}>
  </div>
                <div
                  onClick={() => {
                    navigate("/login");
                  }}
                  style={{display:'flex',justifyContent:'center',alignItems:'center'}}
                >
                  <span
                    className="particulier"
                    style={{
                      cursor: "pointer",
                      textAlign:'center'
                    
                    }}
                  >
                    Se connecter
                  </span>
                </div>
              </div>
            )}

            <div
                className="compte"
                style={{ cursor: "pointer", position: "relative" }}
              >
                <div className="user">
                  <div  className="particulier" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    { "FR"} <ArrowDropDown />
                  </div>
                </div>
              
              </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default Header;
