import {
  GoogleMap,
  InfoWindow,
  Marker,
  OverlayView,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useState } from "react";
import apiServices from "../../services/RequestHandler";
import { defaultLabelColors } from "../../utils/constants";

const containerStyle = {
  width: "100%",
  borderRadius: "32px",
  height: "100%",
  maxHeight: "100%",
};

const options = {
  disableDefaultUI: true, // Disables all default controls
  zoomControl: true, // Enables only the zoom control
  mapTypeControl: false, // Disables the map type control
  streetViewControl: false, // Disables street view control
  fullscreenControl: false, // Disables fullscreen control
};

const center = {
  lat: 48.857244326721165,
  lng: 2.3473757074911936,
};

interface GoogleMapProps {
  handleGetLocation: (data: any) => void;
  markers_data?: Array<any>;
}

function GoogleMaps(props: GoogleMapProps) {
  const [address, setAddress] = useState(null);
  const { handleGetLocation, markers_data } = props;
  const [markerPosition, setMarkerPosition] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {}
  );
console.log("marker",markers_data);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCHQclQrRUyxhjnamOPm4n1z09r-KWmcdQ",
  });
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map: any) {
    // const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds);
    // setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const onMapPress = async (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      try {
        let resp = await apiServices.getFromApi(
          `helper/get-address?lat=${lat}&lng=${lng}`,
          ""
        );
        setMarkerPosition({
          lat,
          lng,
        });
        setAddress(resp);

        if (handleGetLocation) {
          handleGetLocation(resp);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const CustomMarker = ({ item }: any) => {
    console.log("item", item);

    return (
      <div
        className="speech-bubble"
        style={{
          backgroundColor: "white",
          borderRadius: "16px",
          width: "70px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          padding: "15px",
        }}
        onClick={() => alert("Custom Marker Clicked!")}
      >
        <div style={{ flex: 1 }}>
          <svg
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.5 7.27771L12 11.9999M12 11.9999L3.49997 7.27771M12 11.9999L12 21.5M21 16.0585V7.94141C21 7.59876 21 7.42744 20.9495 7.27464C20.9049 7.13947 20.8318 7.01539 20.7354 6.9107C20.6263 6.79236 20.4766 6.70916 20.177 6.54276L12.777 2.43164C12.4934 2.27409 12.3516 2.19531 12.2015 2.16442C12.0685 2.13709 11.9315 2.13709 11.7986 2.16442C11.6484 2.19531 11.5066 2.27409 11.223 2.43165L3.82297 6.54276C3.52345 6.70916 3.37369 6.79236 3.26463 6.9107C3.16816 7.01539 3.09515 7.13947 3.05048 7.27465C3 7.42745 3 7.59877 3 7.94141V16.0585C3 16.4012 3 16.5725 3.05048 16.7253C3.09515 16.8605 3.16816 16.9845 3.26463 17.0892C3.37369 17.2076 3.52345 17.2908 3.82297 17.4572L11.223 21.5683C11.5066 21.7258 11.6484 21.8046 11.7986 21.8355C11.9315 21.8628 12.0685 21.8628 12.2015 21.8355C12.3516 21.8046 12.4934 21.7258 12.777 21.5683L20.177 17.4572C20.4766 17.2908 20.6263 17.2076 20.7354 17.0892C20.8318 16.9845 20.9049 16.8605 20.9495 16.7253C21 16.5725 21 16.4012 21 16.0585Z"
              stroke="#ee6931"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.5 9.5L7.5 4.5"
              stroke="#ee6931"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          style={{
            fontSize: "22px",
            fontWeight: "800",
            fontFamily: "Raleway-Italic",
            color: defaultLabelColors.main_blue,
          }}
        >
          {item?.ship_price}€
        </div>
      </div>
    );
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={13}
      options={options}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={(e) => onMapPress(e)}
    >
      {markers_data?.map((marker: any, index: number) => {
        const pickup = JSON.parse(marker?.pickup?.location ?? "{}");
        // const dropoff = JSON.parse(marker?.dropoff?.location ?? "{}")
        return (
          <>
            <OverlayView
              position={{
                lat: pickup.coordinates[0],
                lng: pickup.coordinates[1],
              }}
              // onClick={() =>
              //   setState({
              //     selected_marker: { ...marker, pickup },
              //     show_window: true,
              //   })
              // }
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <CustomMarker item={marker} />
            </OverlayView>
            {/* <Marker
              position={{
                lat: pickup.coordinates[0],
                lng: pickup.coordinates[1],
              }}
              onClick={() =>
                setState({
                  selected_marker: { ...marker, pickup },
                  show_window: true,
                })
              }
            /> */}
          </>
        );
      })}
      {state.show_window && (
        <InfoWindow
          position={{
            lat: state.selected_marker?.pickup?.coordinates?.[0],
            lng: state.selected_marker?.pickup?.coordinates?.[1],
          }}
          onCloseClick={() =>
            setState({ show_window: false, selected_marker: null })
          }
        >
          <div
            style={{
              height: 100,
              width: 100,
              borderRadius: 16,
              background: "cyan",
            }}
          >
            <h1>AARIIGATOOOO</h1>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}
export default GoogleMaps;
