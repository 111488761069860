import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import React from "react";
import Header from "../../UiComponent/Header";
import { ExpandMore } from "@mui/icons-material";
import { FacebookBlackandWhiteIcon, LinkedinBlackandWhiteIcon, TwitterBlackandWhiteIcon } from "../../assets/images/FrankIcons";

function Blogs(props: any) {
  return (
    <div style={{ padding: "0px 50px 0px 50px",background:'#f9c9b3' }}>
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <Header />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ padding: "0px 100px 0px 100px" }}>
        <Grid xs={12} item>
          <p className="heading-one">
            La Livraison de Particulier à Particulier :<br /> Une Révolution en
            Marche
          </p>
          <p className="paragraph-text" style={{ marginTop: "-10px" }}>
            Lorem ipsum dolor sit amet consectetur. Tortor nibh in massa non sit
            nascetur et. Neque faucibus nec interdum vulputate aliquet lorem
            quis. Mattis tincidunt mi non malesuada aliquet elit. Vulputate
            pulvinar gravida placerat pharetra eu.
          </p>
        </Grid>

        <Grid xs={12} md={8} item>
          <img
            src="https://i.postimg.cc/GpZhWQkG/image-720.png"
            width={"100%"}
            style={{ objectFit: "contain" }}
            alt=""
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <img
            src="https://i.postimg.cc/J087nzYh/image-720.png"
            width={"100%"}
            style={{ objectFit: "contain" }}
            alt=""
          />
          <div
            style={{
              borderRadius: "32px",
              width: "97%",
              background: "#2D26E6",
              padding: "20px",
              color: "#fff",
              marginTop: "20px",
              marginRight: "10px",
            }}
          >
            <p
              style={{
                fontFamily: "Raleway-Variable",
                fontWeight: "500",
                fontSize: "28px",
                textAlign: "left",
              }}
            >
              Introduction
            </p>
            <p className="paragraph-text" style={{ lineHeight: "30px" }}>
              Avec l'essor des plateformes numériques et l'augmentation des
              échanges entre particuliers, la livraison de particulier à
              particulier (P2P) s'impose comme une solution innovante et
              flexible. Ce mode de livraison permet à des particuliers
              d'utiliser leurs déplacements pour transporter des colis pour
              d'autres individus, créant ainsi un réseau de distribution
              dynamique et décentralisé.
            </p>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ padding: "0px 100px 0px 100px" }}>
        <Grid xs={12} md={5} item style={{ marginTop: "20px" }}>
          <div style={{ padding: "20px" }}>
            <div className="div-frame-116" style={{ padding: "15px" }}>
              <div style={{ width: "100%" }}>
                <Accordion
                  elevation={0}
                  style={{
                    border: "0px",
                    background: "#f9c9b3",
                    width: "100%",
                  }}
                >
                  <AccordionSummary
                    style={{ border: "none", width: "100%", fontSize: "22px" }}
                    expandIcon={
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        {" "}
                        <ExpandMore style={{ textAlign: "right" }} />{" "}
                      </div>
                    }
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="span-what-is-frank"
                  >
                    Un Modèle Économique Collaboratif
                  </AccordionSummary>
                  <AccordionDetails style={{ width: "100%" }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </AccordionDetails>
                </Accordion>
                {/* <span className="span-what-is-frank">Qu’est-ce que Frank ?</span>
              <div className="div-line-chevron-down">
                <div className="div-icon"></div>
              </div> */}
              </div>
            </div>
            <div className="div-line-117"></div>

            <div className="div-frame-116" style={{ padding: "15px" }}>
              <div style={{ width: "100%" }}>
                <Accordion
                  elevation={0}
                  style={{
                    border: "0px",
                    background: "#f9c9b3",
                    width: "100%",
                  }}
                >
                  <AccordionSummary
                    style={{ border: "none", width: "100%", fontSize: "22px" }}
                    expandIcon={
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        {" "}
                        <ExpandMore style={{ textAlign: "right" }} />{" "}
                      </div>
                    }
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="span-what-is-frank"
                  >
                    Impact Environnemental Positif
                  </AccordionSummary>
                  <AccordionDetails style={{ width: "100%" }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </AccordionDetails>
                </Accordion>
                {/* <span className="span-what-is-frank">Qu’est-ce que Frank ?</span>
              <div className="div-line-chevron-down">
                <div className="div-icon"></div>
              </div> */}
              </div>
            </div>

            <div className="div-line-117"></div>
            <div className="div-frame-116" style={{ padding: "15px" }}>
              <div style={{ width: "100%" }}>
                <Accordion
                  elevation={0}
                  style={{
                    border: "0px",
                    background: "#f9c9b3",
                    width: "100%",
                  }}
                >
                  <AccordionSummary
                    style={{ border: "none", width: "100%", fontSize: "22px" }}
                    expandIcon={
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        {" "}
                        <ExpandMore style={{ textAlign: "right" }} />{" "}
                      </div>
                    }
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="span-what-is-frank"
                  >
                    Confiance et Sécurité
                  </AccordionSummary>
                  <AccordionDetails style={{ width: "100%" }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </AccordionDetails>
                </Accordion>
                {/* <span className="span-what-is-frank">Qu’est-ce que Frank ?</span>
              <div className="div-line-chevron-down">
                <div className="div-icon"></div>
              </div> */}
              </div>
            </div>

            <div className="div-line-117"></div>
            <div className="div-frame-116" style={{ padding: "15px" }}>
              <div style={{ width: "100%" }}>
                <Accordion
                  elevation={0}
                  style={{
                    border: "0px",
                    background: "#f9c9b3",
                    width: "100%",
                  }}
                >
                  <AccordionSummary
                    style={{ border: "none", width: "100%", fontSize: "22px" }}
                    expandIcon={
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        {" "}
                        <ExpandMore style={{ textAlign: "right" }} />{" "}
                      </div>
                    }
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="span-what-is-frank"
                  >
                    Un Marché en Pleine Expansion
                  </AccordionSummary>
                  <AccordionDetails style={{ width: "100%" }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </AccordionDetails>
                </Accordion>
                {/* <span className="span-what-is-frank">Qu’est-ce que Frank ?</span>
              <div className="div-line-chevron-down">
                <div className="div-icon"></div>
              </div> */}
              </div>
            </div>

            <div className="div-line-117"></div>
          </div>
        </Grid>

        <Grid xs={12} md={7} item>
          <p className="heading-two">Un Modèle Économique Collaboratif</p>
          <p className="paragraph-text">
            La livraison de particulier à particulier repose sur l'économie
            collaborative, où l'usage des ressources existantes est optimisé.
            Les particuliers qui voyagent, que ce soit pour le travail, les
            loisirs ou des raisons personnelles, peuvent rentabiliser leurs
            trajets en transportant des colis. Les expéditeurs, de leur côté,
            bénéficient d'une alternative souvent plus rapide et moins coûteuse
            que les services de livraison traditionnels. Cette approche réduit
            les coûts pour les deux parties, tout en générant un revenu
            complémentaire pour les livreurs.
          </p>
          <div style={{ height: "350px" }}>
            <img
              src="https://i.postimg.cc/fWvS9GcP/image-720.png"
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
              alt=""
            />
          </div>

          <p className="heading-two">Impact Environnemental Positif</p>
          <p className="paragraph-text">
            L'impact environnemental de ce mode de livraison est notable. En
            utilisant des trajets déjà prévus, les émissions de CO2 liées à la
            logistique sont réduites, contrairement aux modèles de livraison
            classiques qui nécessitent des véhicules supplémentaires. De plus,
            la livraison P2P encourage une utilisation plus rationnelle des
            ressources, contribuant ainsi à une diminution de l'empreinte
            carbone collective.
          </p>

          <p className="heading-two">Confiance et Sécurité</p>
          <p className="paragraph-text">
            L'un des défis majeurs de la livraison de particulier à particulier
            réside dans la confiance et la sécurité. Les plateformes numériques
            jouent un rôle crucial en mettant en place des systèmes de
            vérification d'identité, des évaluations par les utilisateurs, et
            des garanties d'assurance pour protéger à la fois les expéditeurs et
            les livreurs. La transparence et la traçabilité offertes par ces
            plateformes sont essentielles pour instaurer un climat de confiance.{" "}
          </p>


          <div style={{ height: "350px" }}>
            <img
              src="https://i.postimg.cc/zBr3L7V5/image-720.png"
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
              alt=""
            />
          </div>

          <p className="heading-two">Un Marché en Pleine Expansion</p>
          <p className="paragraph-text">
          Le marché de la livraison P2P est en pleine expansion. De nombreuses start-ups se sont lancées dans ce créneau, proposant des applications mobiles intuitives et des services personnalisés. Ces entreprises capitalisent sur la flexibilité et la réactivité offertes par ce modèle pour répondre aux attentes des consommateurs modernes. Les secteurs de la vente en ligne entre particuliers, tels que les marketplaces de seconde main, profitent particulièrement de cette dynamique.{" "}
          </p>

          <p className="heading-two">Vers un Avenir Durable et Solidaire</p>
          <p className="paragraph-text">
          En conclusion, la livraison de particulier à particulier représente une révolution dans le domaine de la logistique. Elle incarne une réponse innovante aux défis économiques et environnementaux contemporains, tout en favorisant une solidarité entre les membres de la communauté. À mesure que cette pratique se développe, elle pourrait transformer de manière significative notre manière de concevoir la livraison et le transport de marchandises.{" "}
          </p>


          <p className="paragraph-text">
          L'avenir de la livraison réside peut-être dans la capacité des individus à se mobiliser pour une cause commune, optimisant ainsi les ressources disponibles et renforçant les liens sociaux. La livraison de particulier à particulier est plus qu'un simple service : c'est un mouvement vers un mode de vie plus durable et collaboratif.
          </p>

          <p className="heading-two" style={{fontSize:'24px'}}>partager cet article sur</p>

<div style={{display:'flex',gap:'20px',marginBottom:'20px'}}>
    <FacebookBlackandWhiteIcon />
    <TwitterBlackandWhiteIcon />
    <LinkedinBlackandWhiteIcon />
</div>

        </Grid>
      </Grid>
    </div>
  );
}

export default Blogs;
