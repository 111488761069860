import { Grid } from "@mui/material";
import React, { useState } from "react";
import { defaultLabelColors } from "../../utils/constants";
import { strings } from "../../Store/Localization";
import InputField from "../../UiComponent/TextFeild";
import {
  East,
  LockOpen,
  MailOutline,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import PasswordStrengthBar from "react-password-strength-bar";
import CustomButton from "../../UiComponent/CustomButton";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import apiServices from "../../services/RequestHandler";
import Toast from "../../UiComponent/Toast/Toast";
import { style } from "@mui/system";

function ForgotPassword() {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      showOtpFeild: false,
      showResetpassword: false,
      viewPassword: false,
      viewConfirmPassword: false,
    }
  );
  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;
      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  const handleForgotPassword = async () => {
    try {
      if (!state?.username) {
        Toast.Error("Username cannot be empty");
        return;
      }
      let obj = {
        username: state?.username,
      };
      let resp = await apiServices.postFromApi("c2c/forgot-password", obj, "");

      if (resp?.status === 201 || resp?.status === 200) {
        alert(`OTP ${resp?.otp}`);
        setState({ showOtpFeild: true, disableEmail: true });
        return;
      } else {
        Toast.Error(
          JSON.stringify(
            resp?.data?.error ??
              resp?.error?.error ??
              resp?.data?.isEmail ??
              resp?.data?.isNotEmpty ??
              "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team."
          )
        );
      }
    } catch (error) {}
  };

  const handleVerifyOtp = async () => {
    try {
      if (!otp) {
        Toast.Error("otp cannot be empty");
        return;
      }
      let obj = {
        username: state?.username,
        otp,
      };
      let resp = await apiServices.postFromApi(
        "c2c/validate-reset-code",
        obj,
        ""
      );

      if (resp?.status === 201 || resp?.status === 200) {
        Toast.showSuccess(resp?.message);
        setState({
          showOtpFeild: false,
          disableEmail: false,
          showResetpassword: true,
        });
        return;
      } else {
        Toast.Error(
          JSON.stringify(
            resp?.data?.error ??
              resp?.error?.error ??
              resp?.data?.isEmail ??
              resp?.data?.isNotEmpty ??
              "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team."
          )
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const resetPassword = async () => {
    try {
      try {
        if (!state?.password) {
          Toast.Error("password should not empty");
          return;
        }
        if (!state?.password_confirm) {
          Toast.Error("confirm password should not be empty");
          return;
        }

        if (state?.password !== state?.password_confirm) {
          Toast.Error("Password should be same");
          return;
        }
        let obj = {
          username: state?.username,
          password: state?.password,
          password_confirm: state?.password_confirm,
          otp: otp,
        };
        let resp = await apiServices.postFromApi("c2c/reset-password", obj, "");

        if (resp?.status === 201 || resp?.status === 200) {
          Toast.showSuccess(resp?.message);
          setState({
            showOtpFeild: false,
            disableEmail: false,
            showResetPassword: false,
          });
          //   navigate("/login")
          window.location.reload();
          return;
        } else {
          Toast.Error(
            JSON.stringify(
              resp?.data?.error ??
                resp?.error?.error ??
                resp?.data?.isEmail ??
                resp?.data?.isNotEmpty ??
                "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team."
            )
          );
        }
      } catch (error) {
        console.log("error", error);
      }
    } catch (error) {}
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ padding: "50px" }}
        className="display-flex"
      >
        <Grid container spacing={2}>
          <Grid
            xs={12}
            item
            className="display-flex"
            style={{ display: "flex" }}
          >
            {state?.showResetpassword ? (
              <>
                <Grid
                  container
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    xs={12}
                    item
                    className="display-flex"
                    style={{ flexDirection: "column" }}
                  >
                    <p
                      className="heading-one"
                      style={{
                        fontFamily: "Raleway-Italic",
                        color: defaultLabelColors.main_blue,
                        textAlign: "center",
                      }}
                    >
                      {"Reset Password"}
                    </p>
                    <p
                      className="paragraph-text"
                      style={{
                        marginTop: "-30px",
                        textAlign: "center",
                        fontFamily: "Raleway-Italic",
                        fontWeight: "500",
                        width: "500px",
                      }}
                    >
                      {/* {strings.forgot_password_desc} */}
                    </p>
                  </Grid>

                  <Grid
                    xs={12}
                    item
                    className="display-flex"
                    style={{ textAlign: "center", flexDirection: "column" }}
                  >
                    <InputField
                      input_type={
                        state?.viewPassword === false ? "password" : "text"
                      }
                      onchange={onInputChange}
                      startIcon={<LockOpen />}
                      placeHolder={"Mot de passe "}
                      input_value={state?.password}
                      input_name={"password"}
                      input_style={{
                        background: "#fff",
                        width: "500px",
                        borderRadius: "16px",
                        border: "none",
                      }}
                      endIicon={
                        state?.viewPassword ? (
                          <Visibility
                            onClick={() => {
                              setState({ viewPassword: !state?.viewPassword });
                            }}
                            style={{ color: "gray", cursor: "pointer" }}
                          />
                        ) : (
                          <VisibilityOff
                            onClick={() => {
                              setState({ viewPassword: !state?.viewPassword });
                            }}
                            style={{ color: "gray", cursor: "pointer" }}
                          />
                        )
                      }
                    />
                    <PasswordStrengthBar
                      password={state?.password}
                      style={{ width: "500px" }}
                    />
                  </Grid>

                  <Grid xs={12} item style={{ textAlign: "center" }}>
                    <InputField
                      input_type={
                        state?.viewConfirmPassword === false
                          ? "password"
                          : "text"
                      }
                      onchange={onInputChange}
                      startIcon={<LockOpen />}
                      placeHolder={"Confirmation du mot de passe"}
                      input_value={state?.password_confirm}
                      input_name={"password_confirm"}
                      input_style={{
                        background: "#fff",
                        width: "500px",
                        borderRadius: "16px",
                        border: "none",
                      }}
                      endIicon={
                        state?.viewConfirmPassword ? (
                          <Visibility
                            onClick={() => {
                              setState({
                                viewConfirmPassword:
                                  !state?.viewConfirmPassword,
                              });
                            }}
                            style={{ color: "gray", cursor: "pointer" }}
                          />
                        ) : (
                          <VisibilityOff
                            onClick={() => {
                              setState({
                                viewConfirmPassword:
                                  !state?.viewConfirmPassword,
                              });
                            }}
                            style={{ color: "gray", cursor: "pointer" }}
                          />
                        )
                      }
                    />
                  </Grid>

                  <Grid xs={12} item className="display-flex">
                    <CustomButton
                      style={{
                        width: "260px",
                      }}
                      onClick={resetPassword}
                      classesNames={"contained_button_two"}
                      value={
                        <React.Fragment>
                          <div
                            className="display-flex"
                            style={{
                              columnGap: "10px",
                              padding: "8px 0px 8px 0px",
                              fontSize: "16px",
                            }}
                          >
                            ME CONNECTER
                            <East
                              style={{
                                color: defaultLabelColors.main_blue,
                                background: "#f9d9b6",
                                borderRadius: 100,
                                padding: 4,
                                fontSize: 18,
                              }}
                            />
                          </div>
                        </React.Fragment>
                      }
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid container spacing={2}>
                <Grid
                  xs={12}
                  item
                  className="display-flex"
                  style={{ flexDirection: "column" }}
                >
                  <p
                    className="heading-one"
                    style={{
                      fontFamily: "Raleway-Italic",
                      color: defaultLabelColors.main_blue,
                      textAlign: "center",
                    }}
                  >
                    {strings.Forgot_password_title ?? "Forgot Password"}
                  </p>
                  <p
                    className="paragraph-text"
                    style={{
                      marginTop: "-30px",
                      textAlign: "center",
                      fontFamily: "Raleway-Italic",
                      fontWeight: "500",
                      width: "500px",
                    }}
                  >
                    {strings.forgot_password_desc}
                  </p>
                </Grid>

                <Grid xs={12} item style={{ textAlign: "center" }}>
                  <InputField
                    isDisable={state?.disableEmail}
                    placeHolder={"Email"}
                    onchange={onInputChange}
                    startIcon={<MailOutline />}
                    input_value={state?.username}
                    input_name={"username"}
                    input_style={{
                      background: "#fff",
                      width: "400px",
                      borderRadius: 100,
                    }}
                  />
                </Grid>

                {!state?.showOtpFeild ? (
                  <Grid xs={12} item className="display-flex">
                    <CustomButton
                      style={{
                        width: "260px",
                      }}
                      onClick={handleForgotPassword}
                      classesNames={"contained_button_two"}
                      value={
                        <React.Fragment>
                          <div
                            className="display-flex"
                            style={{
                              columnGap: "10px",
                              padding: "8px 0px 8px 0px",
                              fontSize: "16px",
                            }}
                          >
                            ME CONNECTER
                            <East
                              style={{
                                color: defaultLabelColors.main_blue,
                                background: "#f9d9b6",
                                borderRadius: 100,
                                padding: 4,
                                fontSize: 18,
                              }}
                            />
                          </div>
                        </React.Fragment>
                      }
                    />
                  </Grid>
                ) : null}

                {state?.showOtpFeild ? (
                  <>
                    <Grid
                      xs={12}
                      item
                      style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <OTPInput
                        inputStyle={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "32px",
                          fontWeight: "900",
                          color: "#000",
                          border: "none",
                          background: "#F4F3F6",
                          borderRadius: "12px",
                          padding: "20px",
                        }}
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderSeparator={
                          <span style={{ padding: "15px" }}></span>
                        }
                        renderInput={(props) => <input {...props} />}
                      />
                    </Grid>

                    <Grid xs={12} item className="display-flex">
                      <CustomButton
                        style={{
                          width: "260px",
                        }}
                        onClick={handleVerifyOtp}
                        classesNames={"contained_button_two"}
                        value={
                          <React.Fragment>
                            <div
                              className="display-flex"
                              style={{
                                columnGap: "10px",
                                padding: "8px 0px 8px 0px",
                                fontSize: "16px",
                              }}
                            >
                              ME CONNECTER
                              <East
                                style={{
                                  color: defaultLabelColors.main_blue,
                                  background: "#f9d9b6",
                                  borderRadius: 100,
                                  padding: 4,
                                  fontSize: 18,
                                }}
                              />
                            </div>
                          </React.Fragment>
                        }
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ForgotPassword;
