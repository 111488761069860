/*
  Created By Muhammad Hassaan
  Date 15 April 2023
*/
import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, Paper, TextField } from "@mui/material";
import { defaultLabelColors } from "../../utils/constants";
import { KeyboardArrowDown } from "@mui/icons-material";

export default function SelectField(props: any) {
  const {
    input_label,
    required,
    value,
    classes,
    error,
    options,
    isDisabled,
    name,
    variant,
    placeholder,
    handleSelect,
    readOnly,
    startIcon,
    disableClearable,
    isGetOptionDisable,
    textFeildBackground
  } = props;
  return (
    <form style={{ width: "100%",margin:0,padding:0 }} autoComplete="off" onSubmit={(e) => e.preventDefault()}>
    {input_label && (
      <label className={classNames("Raleway-Variable", error && classes.color_red)} style={{ fontSize: "18px", fontWeight: "600", display: "flex" }}>
        {input_label}
        {required ? <span style={{ color: "red" }}> *</span> : ""}
      </label>
    )}
    <Autocomplete
    style={{border:'none'}}
      options={options}
      getOptionLabel={(option) => option.label}
      fullWidth
      getOptionDisabled={isGetOptionDisable}
      disabled={isDisabled}
      size="small"
      value={value}
      PaperComponent={({ children }) => (
        <Paper
          sx={{
            "& ul": {
              "& li": {
                fontFamily: "Raleway-Variable",
                fontSize: 18,
                fontWeight: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                color: defaultLabelColors.color_e,
                border:'none',
                margin:"15px"
              },
            },
          }}
          style={{
            boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
            maxHeight: 200,
            overflow: "hidden ",
 
          }}
        >
          {children}
        </Paper>
      )}
      popupIcon={<KeyboardArrowDown style={{ color: defaultLabelColors.color_f }} />}
      autoComplete={false}
      onChange={(event, values) => handleSelect(name, event, values)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => (
        <TextField
          name={name}
          {...params}
          size="small"
          autoComplete="off"
          aria-autocomplete="none"
          placeholder={placeholder}
          error={error}
          variant={variant}
          inputProps={{
            ...params.inputProps,
            style: {
              borderRadius: "16px",
              background: textFeildBackground,
              width: "100%",
              border: "none", // Add border
              padding: "10px", // Add padding to match the image
              fontFamily: "Raleway-Italic",
              fontSize: 16,
              fontWeight: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              color: defaultLabelColors.color_e,
              zIndex:1111,
            },
            autocomplete: "off",
            "aria-autocomplete": "none",
          }}
          InputProps={{
            ...params.InputProps,
            style: {
              background: "#f4f3f6",
              paddingRight: 10,
              borderRadius: "16px",
              border: "none", // Add border
            },
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
            startAdornment: <React.Fragment>{startIcon}</React.Fragment>,
            autoComplete: "off",
          }}
          style={{ background: "none",border:'none' }}
          sx={{
            m: 1,
            width: "100%",
            border: 'none', // Add border
            outline: 'none',
            fontFamily: "Raleway-Variable",
            '& .MuiInputBase-input': {
              fontFamily: "Raleway-Variable",
            },
            '& .MuiInputLabel-root': {
              fontFamily: "Raleway-Variable",
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                fontFamily: "Raleway-Variable",
                border:'none',
                background:'#f4f3f6',
                zIndex:111
              },
            },
          }}
        />
      )}
      readOnly={readOnly}
      disableClearable={disableClearable}
    />
  </form>
  );
}
SelectField.defaultProps = {
  input_label: "",
  value: null,
  required: false,
  error: false,
  classes: {},
  size: "small",
  variant: "outlined",
  placeholder: "",
  InputProps: null,
  inputProps: null,
  InputLabelProps: null,
  style: null,
  handleSelect: null,
  options: [],
  isDisabled: false,
  readOnly: false,
  name: "",
  disableClearable: false,
  isGetOptionDisable: null,
  textFeildBackground:"#fff"
};

SelectField.propTypes = {
  input_label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,

  options: PropTypes.any,
  isGetOptionDisable: PropTypes.func,
  value: PropTypes.any,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["outlined", "standard"]),
  required: PropTypes.bool,
  classes: PropTypes.object,
  InputProps: PropTypes.any,
  inputProps: PropTypes.any,
  InputLabelProps: PropTypes.any,
  style: PropTypes.any,
  error: PropTypes.bool,
  isDisabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  disableClearable: PropTypes.bool,
  startIcon: PropTypes.any,
  handleSelect: PropTypes.func,
  textFeildBackground:PropTypes.string
};
