import LocalizedStrings from "react-localization";
export let strings = new LocalizedStrings({
  en: {
    small_desc: ' Between an envelope and a shoebox (less than 5 kg)',
    medium_desc: 'Between a bag and a case of wine (5 kg - 15 kg)',
    large_desc: 'Between a television and a dresser (15 kg - 25 kg)',
    e_large_desc: ' Between a wardrobe and a dining table (more than 25 kg)',
    search: "Search..",
    use_email: "or use your email account.",
    login_with: "Login with",
    signup_with: "Sign up with",
    accept_terms_conditions:
      "I accept the general conditions of use of the Frank",
    accept_newsletter: "I agree to receive latest updates of Frank",
    personal_info: "Personal Information",
    set_credentials: "Set Login Creadentials",
    set_mobile_number: "Enter Your Mobile Number",
    profile: "Profile",
    chat: "Chat",
    support: "Support",
    address: "Address",
    confirmation: "Confirmation",
    logout_confirmation: "Are you sure want to logout from this device?",
    logout: "Logout",
    settings: "Settings",
    mini_shop: "Mini Shop",
    shop_details: "Shop Details",
    order_history: "Order History",

    my_deliveries: "Deliveries",
    my_parcels: "My Announcements",

    signin: "Sign in",
    or_connect: "Or Connect With",
    sign_up: "Create Account",
    set_new_password: "Set New Password",
    wallet: "Wallet",
    blogs: "Frank Blogs",
    blog_details: "Blog Details",
    alread_have_acc: "Already have an account? ",
    login_here: "Login Here",
    signup_here: "Signup Here",
    dont_hace_acc: "Don't have an account? ",
    forgot_password: "Forgot Password",

    continue: "Continue",
    login: "Login",

    first_name: "First Name",
    surname: "Surname",
    date_birth: "Date of Birth",
    user_name: "Username",
    password: "Password",
    old_password: "Old Password",
    phone_number: "Phone Number",
    confirm_password: "Confirm Password",
    next: "Next",
    send_number_text:
      "We will send you a validation code by SMS to verify your number.",
    didnt_receive_code: `Didn't receive code? `,
    resend_code: `Resend Code `,
    send_otp: "Send OTP",
    validate_otp: "Validate OTP",
    send_otp_text: "Enter the One-Time-Passcode (OTP) that has been sent to: ",
    verify_otp: "Verify One Time Passcode (OTP)",
    your_mood: "What are you up to today?",
    sender: "Sender",
    transporter: "Transporter",
    transporter_2: "Transporter",
    relocation: "Relocation",
    send_green_head: "Send Green, Sending Your Way",
    send_green_text: "Each delivery via Frank saves an average of 25 kg of CO₂",

    home: "Home",
    announces: "Announces",
    send: "Send",
    inbox: "Inbox",
    account: "Account",

    reduce_carbon_head: "Reduce",
    reduce_carbon: "your\ncarbon footprint",
    reduce_carbon_title: `Eco-responsible`,
    reduce_carbon_desc: `A parcel sent with Frank it's 25kg/CO2 less! By optimizing your parcel's journey you are doing a favor for the planet.`,

    eco_delivery_head: "Economical\n",
    eco_delivery: "delivery",
    eco_delivery_title: "Economical",
    eco_delivery_desc:
      "A parcel sent with Frank is 50% cheaper than with a traditional carrier. No more ruinous parcel shipments!",

    assured_delivery_head: "Assured\n",
    assured_delivery: "delivery",
    assured_delivery_title: "Assured",
    assured_delivery_desc:
      "A parcel sent with Frank is insured up to 100€. The carrier receives payment once the parcel has delivered and the recipient validated the delivery.",

    recent_transaction: "Recent Transactions",

    create_shipment: "Create Shipment",
    upto_six_pics: "Up to 6 photos, JPG, PNG and GIF format up to 7MB.",
    add_photos: "Add Photos",
    prod_name: "Ex: Sofa, Table",
    i_know_dimensions: "I know the exact dimensions",
    add_descriptive: "Add Descriptive Information Here ..",
    prod_size: "Ex: Regular, Medium, etc.",
    fragile: " Fragile ",
    add_item: "Add Item",
    origin_area: "Origin Area",
    pickup_address: "Pickup Address",
    pickup_place_placholder: "Pick up place",
    delivery_place_placholder: "Delivery place",
    pickup_place_1: "In front of my house",
    pickup_place_2: "Inside my house",

    delivery_type: "Express",
    delivery_type_text: "Between 1 to 2 days",

    delivery_type2: "I can wait",
    delivery_type2_text: "Between 3 to 7 days",

    delivery_type3: "I have time",
    delivery_type3_text: "Between 8 to 30 days",

    back: "Back",
    desitnation_area: "Destination Area",
    dropoff_address: "Dropoff Address",
    continue_: "Continue",

    announcements: "Announcements",
    all: "All",
    around_me: "Around Me",
    online_announce: " online announcements",
    accept: "Accept",
    negotiate: "Negotiate",

    update_info: "Update Information",
    email_address: "Email Address",
    my_profile: "My Profile",

    transfer: "Transfer to a bank account",
    your_wallet: "Your Wallet",
    user_or_email: "Username or Email",
    logging_in_text: "By logging in, you are agree to our",
    terms_condition: "Terms & Conditions",
    privacy_poligy: "Privacy & Policy",

    welcome: "Welcome",
    length: "Length",
    width: "Width",
    height: "Height",
    choose_size: "Package Size",
    when_do_you_want_your_parcel_delivered: "Estimated delivery day",
    make_offer: "Make an offer",
    confirm: "Confirm",
    enter_offer_price: "Enter offer price",
    offer_request_sent: "Your offer has been sent to the Sender! 🎉",
    thanks_frank: "Thanks Frank",
    documents: "Documents",
    notification: "Notifications",
    change_password: "Change Password",
    all_offers: "All Offers",
    announcement_details: "Announcement Details",
    frank_storage: "Storage",
    explore: "Explore",
    shop: "Shop",

    camera: "Camera",
    gallery: "Gallery",
    cancel: "Cancel",
    quantity: "Quantity",
    pickup_place_area: "Pick up place area",
    recipient_name: `Reipient's name`,

    delivery_cost: "Delivery Cost",
    item_sizes: "Item & Sizes",
    type_of_delivery: "Delivery Type",
    sender_2: "Sender",
    sender_details: "Sender Details",
    language: "Language",
    general_cond: "General Conditions",
    payments: "Payments",
    how_many_persons: "De combien de personnes en plus avez-vous besoin ?",
    pickup_date: "Pickup Date",
    delivery_date: "Delivery Date",
    floor: "Floor",
    elevator: "Elevator",
    other_item: `Other Item?`,
    quotation: "Quotation",
    person: "Person",
    elevator_available: "Is there an elevator available?",
    yes: "Yes",
    no: "No",
    dont_need_person: `Don't need person`,
    frank_shop: "Frank Shop",
    particular: "Particular",
    professional: "Professional",
    company_name: "Company Name",
    shop_desc: "Description of your company",
    comp_address: "Address of your company",
    category: "Category",
    categories: "Categories",
    add_logo: `Add Company Logo`,
    add_banner: `Add Company Banner`,
    frank_shop_2: "Frank Shop",
    forgot_password_desc:
      "Forgot your password? No problem! Simply enter your email address to reset it in a few easy steps.",
    prod_title: "Title",
    prod_desc: "Product Description",
    prod_color: "Color",
    prod_price: "Price",
    prod_contition: "Condition",
    optional: "Optional",
    close: "Close",
    not_verified_shop_message:
      "Your request for the Frank store has not yet been approved. We will get back to you in less than 24 hours.",
    cart: "Cart",
    track: "Track",
    total: "Total",
    service_charges: "Platform Fee",
    particular_relocation_desc: "Anyone can deliver your package",
    pro_relocation_desc: "Coming Soon",
    summary: "Summary",
    deliver_order: `Deliver Order`,
    pick_order: `Pick Order`,
    would_like_to: "You would like to",
    proposed_price_placeholder: "Enter proposed price",
    frank_recommended_price: "Frank's recommended price is between ",
    recommended_price_error: `You cannot enter an amount less than Frank's suggested price`,
    minimum_error: "You cannot enter an amount minimum of 1€",
    maximum_price_error: `You cannot enter an amount maximum of 9,999€`,
    reviews: "Reviews",
    messages: "Messages",
    brand: "Brand",
    used_condition: "Condition",
    inactive_shop:
      "Your shop is inactivated by Frank, please contact support for assistance",
    add_logo_particular_shop: "Add Photo to your Shop",
    search_product: "Search Products",
    // new translation
    success: "Success",
    error: "Error",
    try_again: "Try Again",
    email_registered: "Email Registered Successfully",
    title_error: "Title Should not be Empty",
    first_name_error: "Firstname Should not be Empty",
    surname_error: "Surname Should not Be Empty",
    email_error: "Email Should not be left blank or empty",
    success_login: "Successfully Logged In!!",
    internal_error: `Sorry, we're experiencing technical difficulties. Please try again later. If the problem persists, please contact support for assistance`,

    network_failed: "Network Failed",
    network_failed_error:
      "Unable to proceed. Please check your internet connection and try again.",
    apple_auth_error:
      "Unable to authenticate with Apple, For more information please contact us support@frankme.com",
    facebook_auth_error: `Unable to authenticate with Facebook, please try again later. If problem occurs then try contact us at support@frankme.com`,
    user_cancelled_proccess: "User cancelled the authentication process",
    signin_with: "Sign in with ",
    valid_email: `Email should be a valide email`,
    password_error: "Password should not be empty",
    user_not_exists: `User not Exists`,
    mr: "Mr.",
    mrs: "Mrs.",
    ms: "Ms",
    personal_info_msg: `Let's get to know you! Please enter your first name, Surname, and Birthdate`,
    profile_added: "Profile Details Added Successfully",
    user_name_error: "Username Should not Be Empty",
    passowrd_not_match: `Password and confirm Password not matched.`,
    retype_password: "Please Re-type your password",
    set_credentials_success: "Login credentials Have Been Set Successfully",
    password_info: `The Password must be a minimum of 8 characters and include the following requirements`,

    length_desc: `Passwords should be at least 8 characters long`,
    uppercase_letter: `Uppercase Letters: `,
    uppercase_letter_desc: `Include at least one uppercase letter(A-Z).`,
    lower_letter: `Lowercase Letters: `,
    lower_letter_desc: `Include at least one lowercase letter (a-z).`,
    digits_letter: `Digits: `,
    digits_letter_desc: `Include at least one number (0-9)`,
    special_letter: `Special Characters: `,
    special_letter_desc: `Iincorporate at least one Special Character, Such as @,#,!,%,^,&,*,(,).`,
    too_weak: "Too Weak",
    weak: "Weak",
    moderate: "Moderate",
    strong: "Strong",
    very_strong: "Very Strong",
    phone_number_error: `Phone Number Should not be Empty`,
    otp_sent_msg: `A One Time Passcode (OTP) Has Been sent to your Provided Number. Please Enter the otp here`,
    phone_shared: `Your Phone number will be shared with the delivery company or sender so they can arrange the pickup and delivery of your package.`,
    otp_empty_err: `One Tiem Passcode (OTP) Cannot Be left Blank or Emptied`,
    otp_validated: `One Time Passcode (OTP) Has Been Successfully Validated. Now You can Login to your Account.`,
    four_digit_code: `We've Sent a 4-digit code to `,
    enter_otp_email: `Enter the One-Time-Passcode (OTP) that has been sent to `,
    valid_otp: `Please enter a valid OTP`,
    wrong_or_expored_otp: `The OTP has either expired or does not match. Please request a new otp or try Again`,
    valid_otp_mesg:
      "OTP Validated successfully, Please Enter your new password",
    password_reset_mesg: `Your Password Has been Successfully reset! You can now log in with your new password`,
    incorrect_credentials: "Incorrect User name or password",
    add_address: `Add Address`,
    address_saved_msg: `Address Saved to profile Successfully`,
    id_verification: "ID Verification",
    your_verification_status: `Your verification status is `,

    idenfy_head_1_desc:
      "To Ensure Rapid Validations of documents send photos or scans.",
    idenfy_head_1: "In Color: ",
    idenfy_head_2: "Complete: ",
    idenfy_head_3: "eadable and of good Quality: ",
    idenfy_head_2_desc: "Black and white documents will not be accepted.",
    idenfy_head_3_desc:
      "All Pages are present no information is hidden or truncated",
    idenfy_head_4_desc:
      "Neither Blurry.not dark, not damaged and without reflection.",
    start_verification: "Start Verification",
    enable_notif: "Enable Notification",
    enable_notif_desc: `Stay Updated with the latest news, and updates by enabling notifications! Click on the button below to turn on notifications and never miss out on important information and updates`,
    change: "Change",
    view: "View",
    create_shop: `Create your Frank Shop`,
    manage_shop: "Manage Shop",

    add_product: "Add Products",
    manage_product: `Manage Product`,
    view_shop_orders: "View Shop Orders",

    ind_shop_text: `Limited Categories and Yearly sale upto 2500€`,
    pro_shop_text: `Categories and yearly sale Unlimited `,
    products: "Products",
    excellent: "Excellent",
    good: "Good",
    average: "Average",
    below_average: "Below Average",
    poor: "Poor",
    based_on: `based on`,
    overall_rating: "Overall Rating",
    add_to_cart: "Add to Cart",
    item_added: "Item added to cart",
    cart_details: "Cart Details",
    checkout: "Checkout",
    empty_cart: "Empty Cart",

    checkout_confirm: "Are you Sure you want to Checkout?",
    remove_item_confirm: "Are you Sure want to remove this item from the Cart?",
    clear_cart_confirm: "Are you Sure you want to clear your cart?",
    no_items_cart: "There is not items in Cart",
    start_shipping: "Start Shopping",
    free: "Free",
    shipping: "Shipping",
    expected_delivery: "Expected Delivery",
    select_deliery_method: "Please Select Delivery Method",
    warning: "Warning",
    order_summary: "Order Summary",
    get_ur_order: "You will get your order in Between",

    continue_payment: "Contnue to Payment",
    agree_by_order: `By Clicking Placing an order you agree to terms of use and Privacy Policy`,
    confirm_submit_order: "Please Confirm and submit your order",
    order_placed: "Your order has been placed successfully",
    leave_checkout_msg: `No Worries! You can review your cart items before completing order.Press "Back" to return.`,
    leave_checkout: "Leaving Checkout ?",
    upload_6_image: `You Can Upload Upto only 6 Photos`,
    remove_item: `Remove item`,
    select_size: "Please Select the parcel size",
    upload_image: "Please Upload at least 1 image",
    transporter_name: "Transporter Name",

    delivery_otp_code:
      "Give this code to your rider once you received the order.",
    delivery_otp_code_desc:
      "Please ensure this code is only disclosed to your delivery rider",
    small: "Small",
    medium: "Medium",
    large: "Large",
    xl: "Extra Large",
    your_announcements: "Your Announcement",

    declined: "Declined",
    decline: "Decline",
    send_message: "Send a message",
    handling_charges: "Handling Charges",
    sub_total: "Subtotal",
    earned: "Earned",
    view_details: "View Details",
    no_item_found: "No item found",
    select_product: "Select Product",
    search_item: "Search item or product",
    choose_payment_method: "Choose payment method",

    call: "Call",

    male: "Male",
    female: "Female",
    other: "No Gender",
    un_paid: "Unpaid",
    address_error: `Address should not be empty`,
    shop_category_error: `Please select shop category`,
    siret_error: `SIRET should not be empty`,
    company_name_error: "Company name should not be empty",

    product_name_error: "Product Title should not be empty",
    product_price_error: "Product Price should not be 0€",
    product_category_error: "Product Category should not be left blank",
    product_image_error: "Please uplaod atlease 1 product image",
    item_name_error: "Please enter the name or title for Product / Item",
    quantity_error: "Please enter the quantity",
    marked_read: "Marked as Read",

    order_accept_msg: `Did you already pick up the order?`,
    order_deliver_msg: `Are you sure you want to Deliver this Order?\nOnce order is delivered it cannot be reverted!`,

    pickup_otp_message: `Please ask the customer for the OTP to confirm the pickup.\nEnter the OTP below to complete the process`,
    delivery_otp_message: `Please ask the customer for the OTP to confirm the delivery.\nEnter the OTP below to complete the process`,
    otp_confirmation: "OTP Confirmation Required",

    enter_o_password: "Please enter your old Password",
    password_changed_seccess: `Password changed successfully, Please log in again with your new password`,
    enter_n_password: "Please enter your new password",
    leave_feedback: `Leave Feedback`,
    cust_feedback_err: `Customer Rating is required in case of send feedback`,

    out_of_range: "Delivery area is out of range",
    expected_deliver_dates: `Please select expected delivery dates`,
    order_amt: "Order Amount",
    messages_: "Messaging",
    pay_with: `Pay with `,
    relocation_announce: "Relocation",
    track_order: "Track Order",
    tracking_number: `Tracking Number`,
    update_shop: "Update Shop",
    open_setting: "Open Settings",
    done: "Done",
    payment_cancel: "You have cancelled the payment authorization process.",
    sub_category: "Sub category",
    I_ACCEPT_FRANK_TERMS_CONDITION:
      "J'accepte les conditions générales d’utilisation de Frank et avoir au moins 18 ans.",
    I_AGREE_LATEST_UPDATED_FRANK: "J’accepte  de recevoir l’actualité de Frank.",
    All_announes: "All",
    Express_announces: "Express",
    ArountMe_announces: "Around Me",
    Forgot_password_title:'Forgot Password'
  },
  fr: {
    small_desc: 'Entre une enveloppe et une boite à chaussure (<5 Kg)',
    medium_desc: 'Entre un sac à dos et une caisse à vin (5 KG- 15 Kg )',
    large_desc: 'Entre une télévision et une commode (15 kg - 25 kg)',
    e_large_desc: 'Entre une armoire et une table à manger (+25 Kg)',
    search: "Rechercher",
    use_email: "or use your email account.",
    login_with: "Login with",
    signup_with: "Sign up with",
    accept_terms_conditions:
      "Tu confirmes que tu acceptes les Termes & Conditions de Frank et avoir au moins 18 ans",
    accept_newsletter: "J’accepte de recevoir par e-mail les offres de Frank ",
    personal_info: "Information Personnelle",
    set_credentials: "Définir les informations de connexion",
    set_mobile_number:
      "Vérifiez votre numero de telephone pour sécuriser votre compte",
    verify_otp: `Vérifier le code d'accès à usage unique (OTP)`,
    profile: "Profil",
    chat: "Discuter",
    call: "Appeler",
    support: "Support",
    confirmation: "Confirmation",
    logout_confirmation:
      "Êtes-vous sûr de vouloir vous déconnecter de cet appareil ?",
    logout: "Logout",
    settings: "Paramètres",
    mini_shop: "Mini Shop",
    shop_details: "Shop Details",
    order_history: "Transactions",

    my_deliveries: "Deliveries",
    my_parcels: "Mes Annonces",

    signin: "Se connecter",
    or_connect: "Ou conectez-vous avec",
    alread_have_acc: "Vous avez déjà un compte? ",
    login_here: "se connecter",

    sign_up: "Créer un compte",
    forgot_password: "Mot de passe oublié",
    set_new_password: "Définir un nouveau mot de passe",
    wallet: "Porte monnaie",
    blogs: "Frank Blogs",
    blog_details: "Blog Details",
    continue: "S’inscrire",
    login: "Se connecter",

    first_name: "Nom",
    surname: "Prénom",
    date_birth: "Date de Naissance",
    user_name: `Nom d'utilisateur`,
    old_password: "ancien mot de passe",
    password: "Mot de passe",
    confirm_password: "Confirmez le mot de passe",
    next: "S’inscrire",
    phone_number: "Numéro de téléphone",
    send_number_text:
      "Vous recevrez un code par SMS afin d’activer votre compte",

    didnt_receive_code: `Tu n’as pas reçu de code? `,
    resend_code: `Renvoyer le code`,
    send_otp: "Obtenir mon code de vérification",
    validate_otp: "Vérifier",
    send_otp_text: "le code de vérification que vous avez recu sur ce numéro:",

    your_mood: "Quel est le programme du jour?",
    sender: "Envoyer un colis",
    transporter: "Transporter\nun colis",
    transporter_2: "Transporter un colis",
    relocation: "Déménager",
    send_green_head: "Votre Envoi, Moins de CO₂!",
    send_green_text:
      "Chaque livaraison avec Frank réduit en moyenne les émmisions de CO₂ de 25 kg.",

    home: "Accueil",
    announces: "Annonces",
    send: "Envoyer",
    inbox: "Messages",
    account: "Compte",

    //   icon 1 : Livraison écologique
    // icon 2: Livraison économique
    // icon3: Livraison assurée
    //
    reduce_carbon_head: "Livraison\nécologique",
    reduce_carbon: "votre empreinte carbone",
    reduce_carbon_title: "Éco-responsable",
    reduce_carbon_desc:
      "Un colis envoyé avec Frank, c’est 25kg/CO2 en moins ! Vous faites du bien à la planète en optimisant le trajet de votre colis, mais aussi celui d’un transporteur qui, sans vous, aurait peut-être roulé à vide !",

    eco_delivery_head: "Livraison\néconomique",
    eco_delivery: "sur vos envois",
    eco_delivery_title: "Économique",
    eco_delivery_desc:
      "Un colis envoyé avec Frank, c’est 50 % moins cher qu’avec un transporteur traditionnel. Fini les envois de colis ruineux !",

    assured_delivery_head: "Livraison\nassurée",
    assured_delivery: "vos envois",
    assured_delivery_title: "Assuré",
    assured_delivery_desc:
      "Un colis envoyé avec Frank, c’est une assurance à hauteur de 100€. Le transporteur reçoit le montant de sa course une fois que le colis est bien arrivé à destination et que le destinataire en a accusé réception.",

    recent_transaction: "Transactions récentes",

    create_shipment: "Envoyer un colis",
    upto_six_pics: `Jusqu’à 6 photos, formats JPG, PNG et GIF jusqu’à 5 Mb.`,
    add_photos: "Ajouter des photos",
    prod_name: "Ex: Table, Stylo, Four",
    i_know_dimensions: "Je connais les dimensions",
    add_descriptive: "Information complémentaires. ",
    prod_size: "La taille de mon colis",
    fragile: " Fragile ",
    add_item: "Ajouter un colis",
    origin_area: "Ma position actuelle",
    pickup_address: "Adresse de ramassage",
    pickup_place_placholder: "Lieu de prise en charge",
    pickup_place_1: "Devant chez moi",
    pickup_place_2: "Dans la pièce de mon choix",
    delivery_place_placholder: "Lieu de livraison",

    delivery_type: "Express",
    delivery_type_text: "Entre 1 à 2 jours",

    delivery_type2: "Je peux attendre",
    delivery_type2_text: "Entre 3 à 7 jours",

    delivery_type3: "Quand mon annonce trouvera un transporteur",
    delivery_type3_text: "Entre 8 à 30 jours",

    back: "Retour",
    desitnation_area: "Adresse de Livraison",
    dropoff_address: "Adresse de livraison",
    continue_: "Suivant",

    announcements: "Annonces",
    all: "Tout",
    around_me: "Autour de moi",
    online_announce: " annonces en ligne",
    accept: "Accepter",
    negotiate: "Négocier",
    address: "Adresse",

    update_info: "Enregistrer",
    email_address: "Adresse e-mail",
    my_profile: "Mon Profil",

    transfer: "Transférer vers un compte bancaire",
    your_wallet: "Mon porte monnaie",
    user_or_email: "E-Mail ou nom d'utilisateur",

    logging_in_text: "En vous connectant, vous acceptez notre ",
    terms_condition: "Termes & Conditions ",
    privacy_poligy: "Politique de confidentialité ",
    signup_here: "Inscrivez-vous ici",
    dont_hace_acc: "Vous n'avez pas de compte? ",

    welcome: "Bienvenue",
    length: "Longueur",
    width: "Largeur",
    height: "Hauteur",
    choose_size: "Dimension du colis",

    when_do_you_want_your_parcel_delivered:
      "Vous souhaitez que votre colis soit livré …",

    make_offer: "Faire une offre",
    confirm: "Confirmer",
    enter_offer_price: `Entrez le prix de l'offre`,
    offer_request_sent: "Votre offre a été envoyée à l’expéditeur 🎉",
    thanks_frank: "Merci Frank",
    documents: "Documents",
    notification: "Notifications",
    change_password: "Changer le mot de passe",
    all_offers: "Toutes les offres",
    announcement_details: `Détails de l'annonce`,
    frank_storage: "Consigne",
    explore: "Explorer",
    shop: "Boutique",
    camera: "Prendre une photo",
    gallery: "Choisir de ma galerie",
    cancel: "Annuler",
    quantity: "Quantité",
    pickup_place_area: "Lieu de prise en charge",
    recipient_name: "Nom du destinataire",
    delivery_cost: "Frais de livraison",
    item_sizes: "Produit & Dimension",
    type_of_delivery: "Type de livraison",
    sender_2: "Expéditeur",
    sender_details: `À propos de l'expéditeur`,
    language: "Langue",
    general_cond: "Condition générales",
    payments: "Paiement",
    how_many_persons: "De combien de personnes avez-vous besoin?",
    pickup_date: "Date d'enlèvement",
    delivery_date: "Date de livraison",
    floor: "Étage",
    elevator: "Ascenseur",
    other_item: `D’autres articles?`,
    quotation: "Demandez un devis",
    person: "personne",
    elevator_available: "Y a-t-il un ascenseur?",
    yes: "Oui",
    no: "Non",
    dont_need_person: `Je n'ai pas besoin de personne`,
    frank_shop: "Le Marché by Frank",

    particular: "Particulier",
    professional: "Professionnel",
    company_name: "Dénomination de l’entreprise ",
    shop_desc: "Description de votre entreprise",
    comp_address: "Adresse de votre entreprise",
    category: "Catégorie",
    categories: "Catégories",

    add_logo: `Ajouter le logo de l'entreprise`,
    add_banner: `Ajouter une bannière d'entreprise`,
    frank_shop_2: "Marché de Frank",
    forgot_password_desc: `Mot de passe oublié? Aucun problème! Entrez simplement votre adresse e-mail pour la réinitialiser en quelques étapes simples`,

    prod_title: "Titre",
    prod_desc: "Description du produit",
    prod_color: "Couleur",
    prod_price: "Prix",
    prod_contition: "Conditionner",
    optional: "Facultatif",
    close: "Fermer",
    not_verified_shop_message:
      "Votre demande pour la boutique Frank n'a pas encore été approuvée. On revient vers vous dans moins de 24h.",
    cart: "Panier",
    track: "Suivi",
    total: "Total à payer",
    service_charges: "Frais de plateforme",
    particular_relocation_desc: "Un déménagement entre particulier",
    pro_relocation_desc: "À venir",
    summary: "Résumé",

    deliver_order: `Livrer la commande`,
    pick_order: `J'ai récupéré le colis`,
    would_like_to: "Vous souhaitez",
    proposed_price_placeholder: "Proposition tarifaire",
    frank_recommended_price: `Le prix recommandé par Frank est compris entre `,
    recommended_price_error: `vous ne pouvez pas entrer un montant moins que le prix suggéré par Frank`,
    minimum_error: "Vous ne pouvez pas saisir un montant minimum de 1€",
    maximum_price_error: `Vous pouvez saisir un montant maximum de 9,999€`,
    reviews: "Évaluations",
    messages: "Messaging",
    brand: "Marque",
    used_condition: "Etat",
    inactive_shop: `Votre boutique est inactif, veuillez contacter le service support support@frankme.com`,
    add_logo_particular_shop: "Ajouter une photo pour votre profil",
    search_product: "Rechercher un article ou une marque",

    // new translatiions
    success: "Succès",
    error: "Erreur",
    try_again: "Essayer à nouveau",
    email_registered: `E-mail enregistré avec succès`,

    title_error: "Le titre ne doit pas être vide",
    first_name_error: "Le prénom ne doit pas être vide",
    surname_error: "Le nom de famille ne doit pas être vide",
    email_error: `L'e-mail ne doit pas être vide`,
    success_login: "Connection avec succès !!",
    internal_error: `Nous rencontrons des problèmes techniques. Veuillez réessayer, si le problème persiste contactez le service client.`,
    network_failed: "Échec du réseau",
    network_failed_error: `Impossible de continuer. S'il vous plaît, vérifiez votre connexion à internet et réessayez.`,
    apple_auth_error: `Impossible de s'authentifier auprès d'Apple. Pour plus d'informations, veuillez nous contacter support@frankme.com`,
    facebook_auth_error: `Impossible de vous authentifier auprès de Facebook, veuillez réessayer plus tard. Si un problème survient, essayez de nous contacter à support@frankme.com`,
    user_cancelled_proccess: `L'utilisateur a annulé le processus d'authentification`,
    signin_with: "Se connecter avec ",
    valid_email: `L'e-mail doit être un e-mail valide`,
    password_error: "Le mot de passe ne doit pas être vide",
    user_not_exists: `Ce compte n'existe pas !`,
    // Mr,Mrs,Ms	Monsieur, Madame, Mademoiselle
    mr: "Monsieur",
    mrs: "Madame",
    ms: "Mademoiselle",

    personal_info_msg: `Faisons connaissance ! Veuillez saisir votre prénom, votre nom et votre date de naissance`,
    profile_added: `Détails du profil ajoutés avec succès`,
    user_name_error: `Le nom d'utilisateur ne doit pas être vide`,
    passowrd_not_match: `mot de passe et confirmer le mot de passe qui ne correspond pas.`,
    retype_password: "Veuillez retaper le mot de passe",
    set_credentials_success: `Les informations de connexion ont été définies avec succès`,
    password_info: `Le mot de passe doit comporter au moins 8 caractères et inclure les exigences suivantes`,
    length_desc: `les mots de passe doivent comporter au moins 8 caractères.`,
    uppercase_letter: `Lettres majuscules: `,
    uppercase_letter_desc: `Incluez au moins une lettre majuscule (A-Z).`,
    lower_letter: `Lettres minuscules: `,
    lower_letter_desc: `Incluez au moins une lettre minuscule (a-z).`,
    digits_letter: `Chiffres: `,
    digits_letter_desc: `Inclure au moins un chiffre (0-9)`,
    special_letter: `Caractères spéciaux: `,
    special_letter_desc: `Incorporez au moins un caractère spécial, tel que @,#,!,%,^,&,*,(,).`,

    too_weak: "Trop faible",
    weak: "Faible",
    moderate: "Modéré",
    strong: "Fort",
    very_strong: "Très Fort",
    phone_number_error: `Le numéro de téléphone ne doit pas être vide`,
    otp_sent_msg: `Un code d'accès unique (OTP) a été envoyé à votre numéro fourni. Veuillez saisir le otp`,
    phone_shared: `Votre numéro de téléphone sera partagé avec la société de livraison ou l'expéditeur afin qu'ils puissent organiser le ramassage et la livraison de votre colis.`,
    otp_empty_err: `Un mot de passe Tiem (OTP) ne peut pas être vide`,
    otp_validated: `Le code d’accès unique (OTP) a été validé avec succès. Vous pouvez maintenant vous connecter à votre compte.`,
    four_digit_code: `Nous avons envoyé un code à 4 chiffres à `,
    enter_otp_email: `Saisissez le code d'accès à usage unique (OTP) qui a été envoyé à `,
    valid_otp: `Veuillez saisir un OTP valide`,
    wrong_or_expored_otp: `L'OTP a expiré ou ne correspond pas. Veuillez demander un nouvel OTP ou réessayer.`,
    valid_otp_mesg: `OTP validé avec succès, veuillez entrer votre nouveau mot de passe`,
    password_reset_mesg: `Votre mot de passe a été réinitialisé avec succès ! Vous pouvez maintenant vous connecter avec votre nouveau mot de passe`,
    incorrect_credentials: "Identifiant ou mot de passe incorrect",
    add_address: `Ajoutez l'adresse`,
    address_saved_msg: `Adresse enregistrée dans le profil avec succès`,
    id_verification: `Vérification d'identité`,
    your_verification_status: `Votre statut de vérification est `,

    idenfy_head_1_desc: `Pour garantir des validations rapides des documents, envoyez des photos ou des scans`,
    idenfy_head_1: "En couleur: ",
    idenfy_head_2: "Complet: ",
    idenfy_head_3: "Lisible et de bonne qualité: ",
    idenfy_head_2_desc:
      "Les documents en noir et blanc ne seront pas acceptés.",
    idenfy_head_3_desc: `toutes les pages sont présentes, aucune information n'est masquée ou tronquée`,
    idenfy_head_4_desc: "ni flou, ni sombre, ni abîmé et sans reflet",

    start_verification: "Commencer la vérification",
    enable_notif: "Activer les notifications",
    enable_notif_desc: `Restez informé des dernières nouvelles et mises à jour en activant les notifications ! Cliquez sur le bouton ci-dessous pour activer les notifications et ne jamais manquer les informations et mises à jour importantes`,
    change: "Changement",
    view: "Voir",
    create_shop: `Créez votre boutique Frank`,
    manage_shop: "Gérer la boutique",
    add_product: "Ajouter des produits",
    manage_product: `Gérer le produit`,
    view_shop_orders: "Afficher les commandes",

    ind_shop_text: `Catégories limitées et vente annuelles jusqu'à 2500€`,
    pro_shop_text: `Catégories et vente annuelles Illimité`,
    products: "Produits",

    excellent: "Excellent",
    good: "Bien",
    average: "Moyenne",
    below_average: "Moyen",
    poor: "Mauvais",
    based_on: `basé sur`,
    overall_rating: `Note globale`,
    add_to_cart: "Ajouter au panier",
    item_added: `Article ajouté au panier`,
    cart_details: "Panier",

    checkout: "Vérifier",
    empty_cart: `Vider le panier`,

    checkout_confirm: `Etes-vous sûr de vouloir passer à l'étape paiement?`,
    remove_item_confirm: `Êtes-vous sûr de vouloir supprimer cet article du panier?`,
    clear_cart_confirm: `Êtes-vous sûr de vouloir vider votre panier ?`,
    no_items_cart: `Il n'y a aucun article dans le panier`,
    start_shipping: "Commencer vos achats",
    free: "Gratuit",
    shipping: "Expédition",
    expected_delivery: "Livraison prévue",
    select_deliery_method: "Veuillez sélectionner le mode de livraison",
    warning: "Avertissement",

    order_summary: "Récapitulatif de la commande",
    get_ur_order: "Vous receverez votre commande dans les 3 heures",
    confirm_submit_order: "Veuillez confirmer et soumettre votre commande",
    agree_by_order: `En cliquant sur continuer le paiement, vous acceptez les conditions d'utilisation et la politique de confidentialité.`,
    continue_payment: "Continuer le paiement",
    leave_checkout: `Vous quittez la commande ?`,
    leave_checkout_msg: `Pas de soucis! Vous pouvez consulter les articles de votre panier avant de finaliser la commande. Appuyez sur « Retour » pour revenir.`,
    order_placed: "Votre commande a été passée avec succès",
    upload_image: "Veuillez télécharger au moins 1 image",
    select_size: "Veuillez sélectionner la taille du colis",
    remove_item: `Retirer l'objet`,
    upload_6_image: `Vous pouvez télécharger jusqu'à 6 photos seulement`,
    transporter_name: "Nom du transporteur",

    delivery_otp_code:
      "Donnez ce code à votre cavalier une fois la commande reçue.",
    delivery_otp_code_desc: `Veuillez vous assurer que ce code n'est partagé qu'à votre livreur.`,

    small: "Petit",
    medium: "Moyen",
    large: "Large",
    xl: "Extra Large",

    your_announcements: "Votre Annonce",

    declined: "Refusée",
    decline: "Refuser",

    send_message: "Envoyer un message",
    handling_charges: "Frais de service",
    sub_total: "Total",
    earned: "Gagné",
    view_details: "Voir les détails",
    no_item_found: "Aucun article trouvé",
    select_product: `Sélectionner un produit`,
    search_item: "Rechercher un article",

    choose_payment_method: "Choisissez le mode de paiement",

    male: "Monsieur",
    female: "Madame",
    other: "Non binaire",
    un_paid: "Non Payé",

    company_name_error: `Le nom de l'entreprise ne doit pas être vide`,
    siret_error: `SIRET ne doit pas être vide`,
    shop_category_error: `Veuillez sélectionner la catégorie de boutique`,
    address_error: `L'adresse ne doit pas être vide`,

    product_name_error: "Le nom du produit ne peut pas rester vide",
    product_price_error: "Le prix ne peut pas rester vide ou 0€",
    product_category_error: "La catégorie ne peut pas rester vide",
    product_image_error: "Mettez au moins une photo",

    item_name_error: "Veuillez saisir le nom ou le titre du produit/article",
    quantity_error: "Veuillez entrer la quantité",

    marked_read: `Marqué comme lu`,

    order_accept_msg: `Avez-vous récupéré la commande?`,
    // order_accept_msg: `Êtes-vous sûr de vouloir mettre à jour le statut de cette commande de « Acceptée » à « En cours » ?\nUne fois la commande préparée, elle ne peut pas être annulée.!`,
    order_deliver_msg: `Êtes-vous sûr de vouloir livrer cette commande?\nUne fois la commande livrée, elle ne peut pas être annulé`,

    otp_confirmation: "Confirmation OTP requise",
    delivery_otp_message: `Veuillez demander au client l'OTP pour confirmer la livraison.\nEntrez l'OTP ci-dessous pour terminer le processus`,
    pickup_otp_message: `Veuillez demander au client le code de retrait pour confirmer le ramassage`,

    enter_o_password: "Veuillez entrer votre ancien mot de passe",
    password_changed_seccess: `Le mot de passe a été modifié avec succès. Veuillez vous reconnecter avec votre nouveau mot de passe.`,
    enter_n_password: "Veuillez entrer votre nouveau mot de passe",

    leave_feedback: `Laisser un commentaire`,
    cust_feedback_err: `La notation du client est requise en cas de soumission de feedback`,

    out_of_range: `Vous êtes hors de la zone de livraison`,
    expected_deliver_dates: `Por favor seleccione las fechas de entrega previstas`,
    order_amt: "Total de la commande",

    messages_: "Messages",
    pay_with: `Payer avec `,
    relocation_announce: "Déménagement",

    track_order: "Suivre le colis",
    tracking_number: `Numéro de suivi`,
    update_shop: "Mettre à jour la boutique",

    open_setting: "Ouvrir les paramètres",
    done: "Fait",
    payment_cancel: "Vous avez annulé le processus d'autorisation de paiement.",
    sub_category: `Sous-catégorie.`,
 
    All_announes: "Tout",
    Express_announces: "Express",
    ArountMe_announces: "Autour de moi",
    Forgot_password_title:""

  },
});
