import React, { useEffect } from "react";
import facebookIcon from "../../assets/images/FrankIcons/Artboard_1.png";
import instaIcon from "../../assets/images/FrankIcons/Artboard_2.png";
import linkedInIcon from "../../assets/images/FrankIcons/Artboard_3.png";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  DialogContent,
  Grid,
  IconButton,
} from "@mui/material";
import Header from "../../UiComponent/Header";
import { defaultLabelColors, orders, spacing } from "../../utils/constants";
import CustomButton from "../../UiComponent/CustomButton";
import { East, ExpandMore, KeyboardArrowDown, Send } from "@mui/icons-material";
import googlePlayBtn from "../../assets/mobileImages/french-google-play.png";
import footer from "../../assets/images/FrankIcons/download-mobile-app-banner@4x.png";
import playSore from "../../assets/images/FrankIcons/PlayStore-btn.png";
import downloadappStoreBanner from "../../assets/images/FrankIcons/download-app-mobiles@4x.png";

import {
  AppStoreFR,
  FrankIcon,
  MapHomeIcon,
  MapPointerIcon,
  RefrestIcon,
} from "../../assets/images/FrankIcons";
import apiServices from "../../services/RequestHandler";
import InputField from "../../UiComponent/TextFeild";
import GoogleMaps from "../../UiComponent/GoogleMaps";
import { strings } from "../../Store/Localization";
import TransporterUnColisMobile from "./TransporterUnColisMobile";
import CustomFooter from "../../UiComponent/CustomFooter";
import { display, fontSize, minWidth } from "@mui/system";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import moment from "moment";
import { Rating } from "react-simple-star-rating";
import NewHeader from "../../UiComponent/NewHeader";
import transporterUnColisImage from "../../assets/images/FrankIcons/transporterUnColis.png";
import Quest from "../../UiComponent/Quest";
import Toast from "../../UiComponent/Toast/Toast";

function TranspoprterUnColis(props: any) {
  const { classes } = props;
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      announes: "All",
      negotiate: false,
      messagingScreen: false,
    }
  );

  useEffect(() => {
    handleGetAllJobs(state?.announes);
  }, [state?.announes]);

  const handleGetAllJobs = async (jobType: string) => {
    try {
      setState({
        announes: jobType,
      });
      let body: any = {
        type: state?.announes,
      };
      if (state?.announes === "Around Me") {
        navigator.geolocation.getCurrentPosition(function (position) {
          body = {
            ...body,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        });
      }
      const resp = await apiServices.postFromApi(
        "c2c-shipments/web-all-announcements",
        body,
        ""
      );

      setState({
        allJobsData: resp?.data,
      });
      const locations = state?.allJobsData?.pickup?.map(
        (item: any) => item.location
      );
    } catch (error) {}
  };

  const handleGetJobInfo = async (item: any) => {
    try {
      setState({
        openJobDialog: true,
      });
      const resp = await apiServices.getFromApi(
        `c2c-shipments/${item?.id}`,
        ""
      );
      setState({
        jobDetails: resp?.data,
      });
    } catch (error) {}
  };

  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;
      if (value < 1) {
        return;
      }
      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  const handleAcceptJob = async () => {
    try {
      let obj = {
        ship_id: Number(state?.jobDetails?.id),
        ship_price: Number(state?.jobDetails?.ship_price),
      };
      console.log("ship_price", obj);
      let resp = await apiServices.postFromApi("c2c-shipment-rates", obj, "");

      if (resp?.status === 200 || resp?.status === 201) {
        Toast.showSuccess("Job Accepted Succesfully");
        setState({ openJobDialog: false });
      }
    } catch (error) {}
  };

  const handleNegotiate = async () => {
    try {
      if (!state?.negotiated_price) {
        Toast.Error("Please Enter Amount");
        return;
      }
      if (state?.negotiated_price < state?.jobDetails?.ship_price) {
        Toast.Error("Please Enter Valid Amount");
        return;
      }

      let obj = {
        ship_id: Number(state?.jobDetails?.id),
        ship_price: Number(state?.negotiated_price),
      };
      console.log("ship_price", obj);
      let resp = await apiServices.postFromApi("c2c-shipment-rates", obj, "");

      if (resp?.status === 200 || resp?.status === 201) {
        setState({ messagingScreen: true });
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="web-screen">
        <div style={{ background: "#f9c9b3" }}>
          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{
              background: "#F4C9B3",
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "30px",
            }}
          >
            <Grid xs={12} item style={{ zIndex: 99 }}>
              <NewHeader />
            </Grid>
          </Grid>

          <div style={{ padding: "20px 160px 0px 160px" }}>
            <Grid container spacing={spacing.spacing2}>
              <Grid xs={12} md={9.5} item>
                <div>
                  <p className="page-title-one">Transporter un colis </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                    // flexDirection: "row",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <div style={{ width: "150px" }}>
                    <img
                      src={transporterUnColisImage}
                      height={150}
                      width={150}
                      alt=""
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        color: "#000",
                        fontSize: "18px",
                        fontWeight: "700",
                      }}
                    >
                      <span style={{ color: defaultLabelColors.main_blue }}>
                        {" "}
                        Gagnez de l'argent{" "}
                      </span>{" "}
                      en transportant des colis entre particuliers
                    </p>
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "14px",
                      }}
                    >
                      Sélectionnez, transportez, encaissez ! Rien de plus simple
                    </p>
                  </div>
                </div>

                <div>
                  <p className="page-title-two">Annonces</p>
                </div>
                <div style={{ display: "flex", gap: 10 }}>
                  <CustomButton
                    value={"Tout"}
                    classesNames={
                      state?.announes === "All"
                        ? "contained_button"
                        : "outlined_button "
                    }
                    onClick={() => {
                      setState({ announes: "All" });
                    }}
                  />
                  <CustomButton
                    value={"Express"}
                    classesNames={
                      state?.announes === "Express"
                        ? "contained_button"
                        : "outlined_button "
                    }
                    onClick={() => {
                      setState({ announes: "Express" });
                    }}
                  />
                  <CustomButton
                    value={"Autour de moi"}
                    classesNames={
                      state?.announes === "Around Me"
                        ? "contained_button"
                        : "outlined_button "
                    }
                    onClick={() => {
                      setState({ announes: "Around Me" });
                    }}
                  />
                </div>
                <div style={{ height: "350px", marginTop: "10px" }}>
                  <GoogleMaps
                    handleGetLocation={(data) => {
                      setState({
                        destinationAddress: data?.result[0],
                        recipient_digital_code: data?.result[0].zipcode,
                      });
                    }}
                    markers_data={state.allJobsData ?? []}
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <Grid container spacing={2}>
                    {state?.allJobsData?.map((i: any) => {
                      return (
                        <Grid xs={12} sm={12} md={4} item>
                          <div
                            style={{
                              background: "#fff",
                              borderRadius: "16px",
                              padding: "20px",
                              // height: "250px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleGetJobInfo(i);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "Raleway-Italic",
                                    color: "#000",
                                    fontWeight: "700",
                                  }}
                                  className="title-text-announces"
                                >
                                  {i?.shipment_items[0]?.item_name_fr ??
                                    "Arbre a chat"}
                                </p>
                              </div>
                              <div
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontSize: "24px",
                                  fontWeight: "800",
                                  color: defaultLabelColors.main_orange,
                                }}
                              >
                                {i?.ship_price ?? "0.00"}€
                              </div>
                            </div>

                            <div
                              style={{
                                height: "100px",
                                width: "100%",
                                borderRadius: "15px 15px 0px 0px",
                              }}
                            >
                              <img
                                src={i?.shipment_items[0]?.prod_images[0]}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "contain",
                                  borderRadius: "15px 15px 0px 0px",
                                }}
                                alt=""
                              />
                            </div>

                            <div
                              style={{
                                background: "#eef2f6",
                                borderRadius: "16px",
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                marginTop: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                  borderBottom: "1px solid #3026f1",
                                }}
                              >
                                <p
                                  style={{
                                    background: "#f9c9b3",
                                    borderRadius: "32px",
                                    padding: "10px 15px",
                                  }}
                                >
                                  <MapHomeIcon
                                    fill={defaultLabelColors.main_blue}
                                    height={20}
                                    width={20}
                                  />
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontFamily: "Raleway-Italic",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Depart
                                  </span>
                                  <span
                                    style={{
                                      fontFamily: "Raleway-Italic",
                                      fontWeight: "700",
                                      wordBreak: "break-all",
                                      fontSize: "15px",
                                    }}
                                  >
                                    {" "}
                                    {i?.pickup?.city ?? "City"},
                                    {i?.pickup?.country ?? "City"}
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <p
                                  style={{
                                    background: "#f9c9b3",
                                    borderRadius: "32px",
                                    padding: "10px 15px",
                                  }}
                                >
                                  <MapPointerIcon
                                    fill={defaultLabelColors.main_blue}
                                  />
                                </p>
                                <p
                                  style={{
                                    fontFamily: "Raleway-Italic",
                                    fontSize: "16px",
                                  }}
                                >
                                  Destination
                                  <br />
                                  <span
                                    style={{
                                      fontFamily: "Raleway-Italic",
                                      fontWeight: "700",
                                      wordBreak: "break-all",
                                      fontSize: "15px",
                                    }}
                                  >
                                    {" "}
                                    {i?.dropoff?.city ?? "City"},
                                    {i?.dropoff?.country ?? "City"}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <div
                    style={{
                      textAlign: "center",
                      fontFamily: "Raleway-Variable",
                      color: defaultLabelColors.main_blue,
                      fontSize: "22px",
                      fontWeight: "600",
                      padding: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 10,
                      cursor: "pointer",
                    }}
                  >
                    <RefrestIcon fill={defaultLabelColors.main_blue} /> Voir
                    plus d'annonce
                  </div>
                </div>

                <div>
                  <Quest />
                </div>

                <div>
                  <Grid
                    container
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        // width: "1000px",
                        // paddingLeft:'160px',
                        textAlign: "left",
                        fontSize: "42px",
                        fontFamily: "Raleway-Italic",
                        fontWeight: "800",
                        padding: "40px",
                      }}
                    >
                      Nos actualités
                    </div>

                    <div style={{ display: "flex" }}>
                      <ul className="cards">
                        <li className="cards__item">
                          <div className="card">
                            <div className="card__image card__image--river"></div>
                            <div className="card__content">
                              <div style={{ display: "flex" }}>
                                <div
                                  className="card__title"
                                  style={{ flex: 1 }}
                                >
                                  Du nouveau chez Frank
                                  <p
                                    style={{
                                      fontFamily: "Raleway-Italic",
                                      fontSize: "14px",
                                      color: "#000",
                                      fontWeight: "400",
                                      marginTop: 5,
                                    }}
                                  >
                                    il y a 10 heures
                                  </p>
                                </div>
                                <div>
                                  <East
                                    style={{
                                      background: "#fff",
                                      borderRadius: 100,
                                      color: defaultLabelColors.main_blue,
                                      padding: 5,
                                      fontSize: "16px",
                                    }}
                                  />{" "}
                                </div>
                              </div>
                              <p className="card__text">
                                Lorem ipsum dolor sit amet consectetur. Sapien
                                pharetra adipiscing odio et bibendum duis.
                                Auctor scelerisque tellus tortor euismod...
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="cards__item">
                          <div className="card">
                            <div className="card__image card__image--record"></div>
                            <div className="card__content">
                              <div style={{ display: "flex" }}>
                                <div
                                  className="card__title"
                                  style={{ flex: 1 }}
                                >
                                  Du nouveau chez Frank
                                  <p
                                    style={{
                                      fontFamily: "Raleway-Italic",
                                      fontSize: "14px",
                                      color: "#000",
                                      fontWeight: "400",
                                      marginTop: 5,
                                    }}
                                  >
                                    il y a 10 heures
                                  </p>
                                </div>
                                <div>
                                  <East
                                    style={{
                                      background: "#fff",
                                      borderRadius: 100,
                                      color: defaultLabelColors.main_blue,
                                      padding: 5,
                                      fontSize: "16px",
                                    }}
                                  />{" "}
                                </div>
                              </div>
                              <p className="card__text">
                                Lorem ipsum dolor sit amet consectetur. Sapien
                                pharetra adipiscing odio et bibendum duis.
                                Auctor scelerisque tellus tortor euismod...
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="cards__item">
                          <div className="card">
                            <div className="card__image card__image--flowers"></div>
                            <div className="card__content">
                              <div style={{ display: "flex" }}>
                                <div
                                  className="card__title"
                                  style={{ flex: 1 }}
                                >
                                  Du nouveau chez Frank
                                  <p
                                    style={{
                                      fontFamily: "Raleway-Italic",
                                      fontSize: "14px",
                                      color: "#000",
                                      fontWeight: "400",
                                      marginTop: 5,
                                    }}
                                  >
                                    il y a 10 heures
                                  </p>
                                </div>
                                <div>
                                  <East
                                    style={{
                                      background: "#fff",
                                      borderRadius: 100,
                                      color: defaultLabelColors.main_blue,
                                      padding: 5,
                                      fontSize: "16px",
                                    }}
                                  />{" "}
                                </div>
                              </div>
                              <p className="card__text">
                                Lorem ipsum dolor sit amet consectetur. Sapien
                                pharetra adipiscing odio et bibendum duis.
                                Auctor scelerisque tellus tortor euismod...
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Grid>
                </div>

                <Grid
                  container
                  spacing={2}
                  marginTop={5}
                  style={{ padding: 10 }}
                >
                  <Grid
                    xs={10}
                    item
                    className="display-flex"
                    style={{
                      flexDirection: "column",
                      background: "#F5C9B3",
                      padding: "10px",
                      borderRadius: "18px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "36px",
                        fontWeight: "900",
                        fontFamily: "Raleway-Italic",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        textAlign: "left",
                        width: "100%",
                        paddingLeft: "38px",
                        padding: "15px",
                      }}
                    >
                      {" "}
                      Des questions ?
                    </div>

                    <Accordion
                      elevation={0}
                      style={{
                        border: "0px",
                        background: "#F5C9B3",
                        width: "100%",
                      }}
                    >
                      <AccordionSummary
                        style={{ border: "none", width: "100%" }}
                        expandIcon={
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                              fontSize: "28px",
                              fontFamily: "Raleway-Variable",
                            }}
                          >
                            {" "}
                            <ExpandMore style={{ textAlign: "right" }} />{" "}
                          </div>
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="span-what-is-frank"
                      >
                        <h3 style={{ fontWeight: "500" }}>
                          Qu’est-ce que Frank ?
                        </h3>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{ width: "90%" }}
                      ></AccordionDetails>
                    </Accordion>

                    <Accordion
                      elevation={0}
                      style={{
                        border: "0px",
                        background: "#F5C9B3",
                        width: "100%",
                      }}
                    >
                      <AccordionSummary
                        style={{ border: "none", width: "100%" }}
                        expandIcon={
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                              fontSize: "28px",
                              fontFamily: "Raleway-Variable",
                            }}
                          >
                            {" "}
                            <ExpandMore style={{ textAlign: "right" }} />{" "}
                          </div>
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="span-what-is-frank"
                      >
                        <h3 style={{ fontWeight: "500" }}>
                          Comment fonctionne la livraison ??
                        </h3>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{ width: "90%" }}
                      ></AccordionDetails>
                    </Accordion>

                    <Accordion
                      elevation={0}
                      style={{
                        border: "0px",
                        background: "#F5C9B3",
                        width: "100%",
                      }}
                    >
                      <AccordionSummary
                        style={{ border: "none", width: "100%" }}
                        expandIcon={
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                              fontSize: "28px",
                              fontFamily: "Raleway-Variable",
                            }}
                          >
                            {" "}
                            <ExpandMore style={{ textAlign: "right" }} />{" "}
                          </div>
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="span-what-is-frank"
                      >
                        <h3 style={{ fontWeight: "500" }}>
                          Que se passe-t-il si mon colis est perdu / volé ?
                        </h3>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{ width: "90%" }}
                      ></AccordionDetails>
                    </Accordion>

                    <Accordion
                      elevation={0}
                      style={{
                        border: "0px",
                        background: "#F5C9B3",
                        width: "100%",
                      }}
                    >
                      <AccordionSummary
                        style={{ border: "none", width: "100%" }}
                        expandIcon={
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                              fontSize: "28px",
                              fontFamily: "Raleway-Variable",
                            }}
                          >
                            {" "}
                            <ExpandMore style={{ textAlign: "right" }} />{" "}
                          </div>
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="span-what-is-frank"
                      >
                        <h3 style={{ fontWeight: "500" }}>
                          Y a-t-il une limite de poids ?
                        </h3>
                      </AccordionSummary>
                      <AccordionDetails style={{ width: "90%" }}>
                        Y a-t-il une limite de poids ?
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </Grid>
              <Grid md={2.5} item style={{ marginTop: "150px" }}>
                <div>
                  <div
                    className="triangle"
                    style={{ height: "100vh", position: "relative" }}
                  >
                    <div
                      style={{
                        paddingTop: 20,
                        rowGap: 20,
                        display: "flex",
                        flexDirection: "column",
                        zIndex: 111,
                      }}
                    >
                      <FrankIcon fill={"#fff"} height={70} />

                      <p
                        className="heading-two"
                        style={{
                          fontSize: "22px",
                          textAlign: "center",
                          color: "#fff",
                          padding: "0px 10px 0px 10px",
                        }}
                      >
                        <span style={{ fontSize: "18px", fontWeight: "500" }}>
                          Télécharger
                        </span>
                        <br />
                        notre application mobile
                      </p>

                      <div
                        className="display-flex"
                        style={{
                          flexDirection: "column",
                          gap: "10px",
                          marginTop: "-10px",
                        }}
                      >
                        <a
                          href="https://apps.apple.com/app/frank/id6502341979"
                          target="_blank"
                        >
                          {" "}
                          <AppStoreFR height={47} />
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.frankc2c"
                          target="_blank"
                          style={{ textAlign: "center", width: "150px" }}
                        >
                          {" "}
                          <img src={playSore} height={47} />
                        </a>
                      </div>
                    </div>

                    <img
                      src={downloadappStoreBanner}
                      style={{ position: "absolute", bottom: 0, width: "100%" }}
                      alt=""
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div
          className="main-container"
          style={{ background: "#fff", marginTop: "100px" }}
        >
          <CustomFooter />
        </div>

        <DraggableDialog
          maxWidth={`${state?.negotiate ? "sm" : "md"}`}
          open={state?.openJobDialog}
          handleClose={() => {
            setState({
              openJobDialog: false,
              messagingScreen: false,
              negotiate: false,
            });
          }}
          dialog_title={"Transporter un colis"}
          classes={classes}
          dialogBody={
            <DialogContent style={{}} dividers>
              {state?.negotiate ? (
                state?.messagingScreen ? (
                <>
                  <div
                    className="card-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "92%",
                      padding: "20px",
                      height: "60vh",
                    }}
                  >
                    <div style={{flex:1,width:'100%',gap:20}}>
                    <div
                      style={{
                        background: "#eef2f6",
                        borderRadius: "16px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        marginTop: "10px",
                        width: "90%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          borderBottom: "1px solid #3026f1",
                        }}
                      >
                        <p
                          style={{
                            background: "#f9c9b3",
                            borderRadius: "32px",
                            padding: "10px 15px",
                          }}
                        >
                          <MapHomeIcon
                            fill={defaultLabelColors.main_blue}
                            height={20}
                            width={20}
                          />
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Raleway-Italic",
                              fontSize: "16px",
                            }}
                          >
                            Depart
                          </span>
                          <span
                            style={{
                              fontFamily: "Raleway-Italic",
                              fontWeight: "700",
                              wordBreak: "break-all",
                              fontSize: "15px",
                            }}
                          >
                            {" "}
                            {state?.jobDetails?.pickup?.city ?? "City"},
                            {state?.jobDetails?.pickup?.country ?? "City"}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <p
                          style={{
                            background: "#f9c9b3",
                            borderRadius: "32px",
                            padding: "10px 15px",
                          }}
                        >
                          <MapPointerIcon fill={defaultLabelColors.main_blue} />
                        </p>
                        <p
                          style={{
                            fontFamily: "Raleway-Italic",
                            fontSize: "16px",
                          }}
                        >
                          Destination
                          <br />
                          <span
                            style={{
                              fontFamily: "Raleway-Italic",
                              fontWeight: "700",
                              wordBreak: "break-all",
                              fontSize: "15px",
                            }}
                          >
                            {" "}
                            {state?.jobDetails?.dropoff?.city ?? "City"},
                            {state?.jobDetails.dropoff?.country ?? "City"}
                          </span>
                        </p>
                      </div>
                      <div
                        style={{
                          borderTop: "1px solid lightgrey",
                          padding: "10px",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Raleway-Variable",
                            fontSize: "18px",
                            fontWeight: 600,
                            flex: 1,
                          }}
                        >
                          Frais de livraison
                        </div>
                        <div
                          style={{
                            fontFamily: "Raleway-Variable",
                            fontSize: "18px",
                            fontWeight: 600,
                          }}
                        >
                          {state?.jobDetails?.ship_price} €
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        background: "#DCDBFB",
                        borderRadius: "16px",
                        width: "94%",
                        padding: "10px",
                        fontFamily: "Raleway-Variable",
                        fontWeight: "600",
                        marginTop:'20px',
                        fontSize:'16px'

                      }}
                    >
                      Vous avez fait une offre pour une montant de :{" "}
                      {state?.negotiated_price}€
                    </div>
                    </div>

                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%'}}>
                      <div style={{width:'90%'}}>
                      <InputField
                        input_type={"text"}
                        input_value={state?.YourMessage ?? ""}
                        onchange={onInputChange}
                        placeHolder={"Montant négocié"}
                        input_name={"negotiated_price"}
                        input_style={{
                          background: "#fff",
                          borderRadius: "16px",
                          border: "none",
                          fontSize: `64px !important`,
                          width:'100%'
                        }}
                      />
                      </div>
                      <div style={{width:'10%'}}>
                      <IconButton style={{background:defaultLabelColors.main_orange,padding:'10px',borderRadius:100}}> 
                      <Send style={{color:'#fff'}} /> 
                         </IconButton>
                         </div>
                    </div>
                  </div>
                  <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        gap: "10px",
                        paddingTop: "20px",
                      }}
                    >
                      <CustomButton
                        classesNames={"outlined_button"}
                        value={"Retour"}
                        // disable={state?.items?.length > 0 ? true : false}
                        style={{ width: "200px" }}
                        onClick={() => {
                          setState({ negotiate: false });
                        }}
                      />
                      <CustomButton
                        classesNames={"contained_button"}
                        value={"Terminer"}
                        // disable={state?.items?.length > 0 ? true : false}
                        style={{ width: "200px" }}
                        // onClick={handleNegotiate}
                      />
                    </div>
                  </>
                ) : (
                  <div>
                    <div>
                      <div className="card-container">
                        <img
                          src={
                            state?.jobDetails?.shipment_items[0]?.prod_images[0]
                          }
                          className="card-image"
                        />
                        <div className="card-details">
                          <div className="card-title" style={{ fontSize: 20 }}>
                            {state?.jobDetails?.shipment_items[0]?.prod_name ??
                              ""}
                          </div>

                          <div
                            className="card-quantity"
                            style={{
                              color: defaultLabelColors.main_orange,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <p
                              style={{
                                color: "#080626",
                                font: "16px",
                                fontWeight: "500",
                              }}
                            >
                              {" "}
                              Demande négociation :{" "}
                            </p>
                            <span
                              style={{
                                fontSize: "26px",
                                color: defaultLabelColors.main_orange,
                                fontWeight: 600,
                                paddingLeft: "10px",
                              }}
                            >
                              {" "}
                              {state?.jobDetails?.ship_price} €
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="card-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        lineHeight: "1px",
                        marginTop: "20px",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "32px",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        Insérez le montant de votre offre
                      </p>
                      <p
                        style={{
                          textAlign: "center",
                          fontFamily: "Raleway-Variable",
                        }}
                      >
                        Offre(s) restante(s) : 25
                      </p>
                      <p
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        cette limite permet aux membres de prendre le temps de
                        les passer en revue
                      </p>
                      <div className="display-flex">
                        <InputField
                          input_type={"number"}
                          input_value={state?.negotiated_price ?? ""}
                          startIcon={"ex :"}
                          onchange={onInputChange}
                          input_name={"negotiated_price"}
                          input_style={{
                            background: "#F4F3F6",
                            width: "50%",
                            borderRadius: "16px",
                            border: "none",
                            height: "100px",
                            fontSize: `64px !important`,
                          }}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        gap: "10px",
                        paddingTop: "20px",
                      }}
                    >
                      <CustomButton
                        classesNames={"outlined_button"}
                        value={"Annuler"}
                        // disable={state?.items?.length > 0 ? true : false}
                        style={{ width: "100%" }}
                        onClick={() => {
                          setState({ negotiate: false });
                        }}
                      />
                      <CustomButton
                        classesNames={"contained_button"}
                        value={"Négocier"}
                        // disable={state?.items?.length > 0 ? true : false}
                        style={{ width: "100%" }}
                        onClick={handleNegotiate}
                      />
                    </div>
                  </div>
                )
              ) : (
                <>
                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: 20,
                      gap: 10,
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Variable",
                          fontSize: "16px",
                        }}
                      >
                        Détail de l’expéditeur
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          fontFamily: "Raleway-Variable",
                          fontSize: "16px",
                        }}
                      >
                        Membre depuis
                        {moment(
                          state?.jobDetails?.user_details?.created_at
                        ).format("YYYY") ?? "recently"}
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          width: "15%",
                          paddingTop: "20px",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            position: "relative",
                            width: 100,
                            height: 100,
                            borderRadius: 100,
                            overflow: "hidden",
                            display: "flex",

                            boxShadow: 3,
                          }}
                        >
                          <img
                            src="https://d2v5dzhdg4zhx3.cloudfront.net/web-assets/images/storypages/short/linkedin-profile-picture-maker/dummy_image/thumb/001.webp" // replace with your image path
                            alt="Profile"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </Box>
                      </div>
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-end",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p
                            style={{
                              fontFamily: "Raleway-Variable",
                              fontSize: "22px",
                              fontWeight: 600,
                            }}
                          >
                            {state?.jobDetails?.user_details?.cus_name ??
                              "Customer Name"}
                          </p>
                          <Rating
                            disableFillHover
                            initialValue={
                              state?.jobDetails?.user_details?.cus_ratings ?? 2
                            }
                            allowHover={false}
                            size={40}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: "0px 20px 20px 20px",
                      gap: 10,
                      marginTop: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                      }}
                    >
                      Type de livraison
                    </p>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Variable",
                          fontWeight: "600",
                          fontSize: "24px",
                        }}
                      >
                        {state?.jobDetails?.ship_delivery_type ?? "Express"}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Raleway-Variable",
                          fontSize: "16px",
                        }}
                      >
                        Annonce créée le{" "}
                        {moment(state?.jobDetails?.created_at).format(
                          "DD/MMM/YYYY"
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: "0px 20px 20px 20px",
                      gap: 10,
                      marginTop: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                      }}
                    >
                      Products & dimension
                    </p>

                    <div>
                      {state?.jobDetails?.shipment_items?.map((i: any) => {
                        return (
                          <div className="card-container">
                            <img
                              src={i?.prod_images[0]}
                              className="card-image"
                            />
                            <div className="card-details">
                              <div
                                className="card-title"
                                style={{ fontSize: 17 }}
                              >
                                {i?.prod_name ?? ""}
                              </div>
                              <div className="card-dimensions">
                                {i?.prod_weight ?? ""}
                              </div>
                              <div className="card-quantity">
                                x{i?.prod_quantity}
                              </div>
                            </div>
                            <div
                              className="card-quantity"
                              style={{ paddingRight: "20px" }}
                            >
                              {i?.cat_name}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: "20px 20px 20px 20px",
                      gap: 10,
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Variable",
                          fontWeight: "700",
                          fontSize: "24px",
                        }}
                      >
                        {"Frais de livraison"}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "600",
                          fontFamily: "Raleway-Variable",
                          fontSize: "22px",
                          color: defaultLabelColors.main_blue,
                        }}
                      >
                        {state?.jobDetails?.ship_price} €
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      gap: "10px",
                      paddingTop: "20px",
                    }}
                  >
                    <CustomButton
                      classesNames={"outlined_button"}
                      value={"Négocier"}
                      // disable={state?.items?.length > 0 ? true : false}
                      style={{ width: "200px" }}
                      onClick={() => {
                        setState({ negotiate: true });
                      }}
                    />
                    <CustomButton
                      classesNames={"contained_button"}
                      value={"Accepter"}
                      // disable={state?.items?.length > 0 ? true : false}
                      style={{ width: "200px" }}
                      onClick={handleAcceptJob}
                    />
                  </div>
                </>
              )}
            </DialogContent>
          }
        />
      </div>

      <div>
        <div className="mobile-screen">
          <TransporterUnColisMobile />
        </div>
      </div>
    </>
  );
}

export default TranspoprterUnColis;
