import { Checkbox, DialogContent, FormControlLabel, FormGroup, Grid, Radio, TextField } from "@mui/material";
import React, { useEffect } from "react";
import Header from "../../UiComponent/Header";
import {
  defaultLabelColors,
  PACKAGE_SHIPMENT_TYPE,
  sizes_values,
} from "../../utils/constants";
import GoogleMaps from "../../UiComponent/GoogleMaps";
import {
  CartIcon,
  HandClapImage,
  HomeIcon,
  HomeIconBlue,
  LocationPinIcon,
  MapHomeIcon,
} from "../../assets/images/FrankIcons";
import { getDataFromLocalStorage } from "../../utils/LocalStore.util";
import InputField from "../../UiComponent/TextFeild";
import SelectField from "../../UiComponent/SelectField";
import CustomButton from "../../UiComponent/CustomButton";
import FlagInputField from "../../UiComponent/FlagInput";
import { KeyboardArrowRight, MarginOutlined } from "@mui/icons-material";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import { textAlign } from "@mui/system";
import { useNavigate } from "react-router-dom";
import GoogleAutocompleteField from "../../UiComponent/GoogleAutocompleteField";
import ReactGoogleMap from "../../UiComponent/ReactGoogleMap";
import NewHeader from "../../UiComponent/NewHeader";
import { calculateDistance } from "../../utils/helper";
import apiServices from "../../services/RequestHandler";
import _ from "lodash";
import { strings } from "../../Store/Localization";

const apiKey = "AIzaSyCHQclQrRUyxhjnamOPm4n1z09r-KWmcdQ"; // Replace with your actual API key

function CheckoutPackage(props: any) {
  const { classes } = props;
  const [selectedValue, setSelectedValue] = React.useState("");
  const navigate = useNavigate();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      screenStep: 1,
    }
  );
  useEffect(() => {
    const anoucmet_data = getDataFromLocalStorage("AnouncesData");
    setState({
      details: anoucmet_data,
    });
    console.log("announcesData", state?.details);


  }, []);

  useEffect(() => {
    if (state?.screenStep === 3) {
      handleCalculateShippingCharges();
    }
  }, [state?.screenStep])
  

  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;
      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  const handleSelectForProduct = (name: any, event: any, value: any): void => {
    try {
      let { items } = state;
      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  const onCheckboxChange = (e: any) => {
    try {
      const { name, checked } = e.target;

      setState({
        [name]: checked,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleNext = () => {
    try {
      if (state?.screenStep <= 3) {
        setState({ screenStep: state?.screenStep + 1 });
        return;
      }
      setState({ openJobTypeDialog: true });
    } catch (error) {}
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleChangeMobileCode = (dialcode: any, codeName: any) => {
    try {
      setState({
        [codeName]: dialcode,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeMobile = (event: any) => {
    try {
      setState({
        [event.target.name]: event.target.value,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const enableFlagInput = (state: any, value: any) => {
    try {
      setState({
        [state]: value,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handlePlaceSelected = (
    place: google.maps.places.PlaceResult | null
  ) => {
    if (place) {
      const address = place.formatted_address;
      // const lat = place.geometry?.location?.lat();
      // const lng = place.geometry?.location?.lng();
      // console.log("Selected Place:", { address, lat, lng });
      // console.log("address",place.geometry);

      if (state?.screenStep === 1) {
        setState({
          originAddress: place,
        });
        return;
      } else {
        setState({
          destinationAddress: place,
        });
      }
    } else {
      console.log("No place details available.");
    }
  };

  const handleCalculateShippingCharges = async () => {
    try {
      const address_distance = calculateDistance(
        state?.originAddress?.geometry?.location?.lat(),
        state?.originAddress?.geometry?.location?.lng(),
        state?.destinationAddress?.geometry?.location?.lat(),
        state?.destinationAddress?.geometry?.location?.lng()
      );
      console.log("state?.details", state?.details);

      const item_sizes = state?.details?.map((i: any) => {
        return sizes_values[i.productSize[0]?.size as any];
      });
      console.log("itemSize", item_sizes);

      const shipping_price = await apiServices.postFromApi(
        "c2c-shipment-prices/get-distance-sizes",
        {
          distance: Number(address_distance),
          sizes: item_sizes,
        },
        ""
      );
      console.log("shipping_price",shipping_price);
      

      const platform_charges = shipping_price?.platform_charges ?? [];
      const price_resp = shipping_price?.result ?? [];

      const grouped_price = _.groupBy(price_resp, "size_type");

      console.log("grouped_price",grouped_price);
      

      for (const key in grouped_price) {
        if (Object.prototype.hasOwnProperty.call(grouped_price, key)) {
          const element = grouped_price[key];
          const suggested_price = element.reduce((acc: any, curr: any) => {
            acc[curr.key] =
              curr.key == "Average" ? curr.price_to : curr.price_from;
              console.log("acc",acc[curr.key]);
              
            return acc;
          }, {});
          if (key === "S") {
            setState({
              ship_price: suggested_price.Recommended,
              suggested_price,
              // platform_fee
            });
          }
          if (key === "M") {
            setState({
              ship_price: `${suggested_price.Recommended}`,
              suggested_price,
            });
          }
          if (key === "L") {
            setState({
              ship_price: `${suggested_price.Recommended}`,
              suggested_price,
            });
          }
          if (key === "XL") {
            setState({
              ship_price: `${suggested_price.Recommended}`,
              suggested_price,
            });
          }
          console.log("ship_price",state?.ship_price);
          
          
        }
      }
      const maxLevel = Math.max(
        ...state?.details?.map((item: any) => item.productSize[0]?.level)
      );

      console.log("maxLevel",maxLevel);
      

      const maxItem = state?.details?.find(
        (i: any) => i?.productSize[0]?.level == maxLevel
      );
      const item_size_ = maxItem?.productSize[0]?.size;
      console.log("item_size_",item_size_);
      

      const findPlatformCharges = platform_charges?.find((item: any) => {
        // if (state?.details?.productSize[0]?.id === 5) {
        //   return item.size == "Relocation";
        // }
        if (item_size_ == "small") {
          return item.size == "S";
        }
        if (item_size_ == "Medium") {
          return item.size == "M";
        }
        if (item_size_ == "Large") {
          return item.size == "L";
        }
        if (item_size_ == "Extra Large") {
          return item.size == "XL";
        }
      });

      setState({
        findPlatformCharges,
      });

      console.log("findPlatformCharges", state?.findPlatformCharges);
    } catch (error) {
      console.log("error",error);
      
    }
  };

  // let findPlatformCharges = 0;

  return (
    <div style={{ background: "#F9C7B8" }}>
      <Grid container>
        <Grid xs={12} item style={{ padding: "40px 30px 0px 30px" }}>
          <NewHeader />
        </Grid>
      </Grid>

      <div style={{ padding: "20px 160px 40px 160px" }}>
        <Grid container spacing={2}>
          <Grid
            xs={12}
            md={state?.screenStep < 3 ? 8 : 12}
            item
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                maxWidth: "500px",
                height: "100px",
                width: "100%",
                display: "flex",
                gap: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: defaultLabelColors.main_orange,
                  borderRadius: "32px",
                  width: "30%",
                  height: "10px",
                }}
              ></div>
              <div
                style={{
                  background:
                    state?.screenStep === 2 || state?.screenStep === 3
                      ? defaultLabelColors.main_orange
                      : "#E3CFC7",
                  borderRadius: "32px",
                  width: "30%",
                  height: "10px",
                }}
              ></div>
              <div
                style={{
                  background:
                    state?.screenStep === 3
                      ? defaultLabelColors.main_orange
                      : "#E3CFC7",
                  borderRadius: "32px",
                  width: "30%",
                  height: "10px",
                }}
              ></div>
              <div
                style={{
                  fontSize: "16px",
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "Raleway-Variable",
                }}
              >
                <p style={{ display: "flex" }}>
                  {" "}
                  étape{" "}
                  <span
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontWeight: "600",
                      paddingLeft: 5,
                    }}
                  >
                    {" "}
                    {state?.screenStep}/3{" "}
                  </span>
                </p>
              </div>
            </div>

            {state?.screenStep === 1 ? (
              <>
                <div style={{ maxWidth: "600px" }}>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "32px",
                      fontWeight: "700",
                    }}
                  >
                    Adresse de ramassage
                  </p>

                  <p style={{ fontFamily: "Raleway-Variable", fontSize: "18" }}>
                    Insérez l’adresse de ramassage des objets à déménager
                  </p>
                  <div style={{ height: "200px", marginLeft: 10 }}>
                    <GoogleMaps handleGetLocation={(data) => {}} />
                    {/* <ReactGoogleMap
                    selectedAddress={state?.markerData}
                    handleGetLocation={(data)=>{}}
                     /> */}
                  </div>
                  <div
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: 22,
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                      marginTop: "20px",
                      marginLeft: 10,
                    }}
                  >
                    <HomeIconBlue
                      fill={defaultLabelColors.main_blue}
                      height={26}
                      width={26}
                    />{" "}
                    Adresse de ramassage
                  </div>

                  <div>
                    <GoogleAutocompleteField
                      apiKey={apiKey}
                      placeholder="Enter a location"
                      onPlaceSelected={handlePlaceSelected}
                      styles={{
                        background: "#F4F3F6",
                        width: "92%",
                        borderRadius: "16px",
                        border: "none",
                        fontSize: "16px",
                        float: "left",
                        margin: "20px 0px",
                        padding: "20px",
                        marginLeft: 9,
                        zIndex: 1111,
                      }}
                    />
                    {/* <InputField
                    placeHolder={"Adresse de ramassage"}
                    input_value={state.originAddress ?? ""}
                    onchange={onInputChange}
                    input_name={"originAddress"}
                    input_style={{
                      background: "#F4F3F6",
                      width: "100%",
                      borderRadius: "16px",
                      border: "none",
                      fontSize: "16px",
                      float: "left",
                    }}
                  /> */}
                  </div>
                  <div>
                    <SelectField
                      placeholder={"Lieu de prise en charge"}
                      textFeildBackground={"#F4F3F6"}
                      handleSelect={(name, event, value) =>
                        handleSelectForProduct(name, event, value)
                      }
                      options={[
                        { label: "Devant chez moi", value: "Devant chez moi" },
                        {
                          label: "Dans la pièce de mon choix",
                          value: "Dans la pièce de mon choix",
                        },
                      ]}
                      value={state?.pickupLocation}
                      name={"pickupLocation"}
                    />
                  </div>
                  <div style={{ marginLeft: "10px", width: "100%" }}>
                    {PACKAGE_SHIPMENT_TYPE?.map((i: any) => {
                      return (
                        <>
                          <div
                            style={{
                              borderRadius: "16px",
                              background: "#F4F3F6",
                              padding: 15,
                              fontFamily: "Raleway-Variable",
                              cursor: "pointer",
                              marginTop: 5,
                              border: `2px solid ${
                                selectedValue === i?.type
                                  ? defaultLabelColors.main_blue
                                  : "#f3f3f3"
                              }`,
                            }}
                          >
                            <div
                              style={{
                                fontWeight: "800",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: 1 }}>{i?.type}</div>
                              <div>
                                <Radio
                                  checked={selectedValue === i?.type}
                                  onChange={handleChange}
                                  value={i?.type}
                                  name="type"
                                  style={{ color: "#000" }}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                fontWeight: "700",
                                fontSize: "14px",
                                marginTop: "10px",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "500",
                                  fontFamily: "Raleway-Italic",
                                }}
                              >
                                {i?.data}
                              </span>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "20px",
                      gap: 10,
                    }}
                  >
                    <CustomButton
                      classesNames={
                        state?.screenStep === 1
                          ? "outlined_button_disable"
                          : "outlined_button"
                      }
                      value={"Retour"}
                      style={{ width: "150px" }}
                      disable={state?.screenStep === 1 ? true : false}
                      onClick={() => {
                        setState({
                          screenStep: state?.screenStep - 1,
                        });
                      }}
                    />

                    <CustomButton
                      classesNames={"contained_button"}
                      value={"Suivant"}
                      style={{ width: "150px" }}
                      onClick={() => {
                        setState({
                          screenStep: state?.screenStep + 1,
                        });
                      }}
                    />
                  </div>
                </div>
              </>
            ) : state?.screenStep === 2 ? (
              <div style={{ maxWidth: "600px" }}>
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "32px",
                    fontWeight: "700",
                  }}
                >
                  Adresse & type de livraison
                </p>

                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "18",
                    marginTop: "0",
                  }}
                >
                  Insérez l’adresse de livraison souhaitée et fixez un délai
                </p>
                <div style={{ height: "200px" }}>
                  <GoogleMaps
                    handleGetLocation={(data) => {
                      setState({});
                    }}
                  />
                </div>
                <div
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: 22,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                    marginTop: "20px",
                  }}
                >
                  <LocationPinIcon
                    fill={defaultLabelColors.main_orange}
                    height={26}
                    width={26}
                  />{" "}
                  Adresse de livraison
                </div>

                <div>
                  <GoogleAutocompleteField
                    apiKey={apiKey}
                    placeholder="Enter a location"
                    onPlaceSelected={handlePlaceSelected}
                    styles={{
                      background: "#F4F3F6",
                      width: "92%",
                      borderRadius: "16px",
                      border: "none",
                      fontSize: "16px",
                      float: "left",
                      margin: "20px 0px",
                      padding: "20px",
                      marginLeft: 9,
                      zIndex: 1111,
                    }}
                  />
                </div>
                {/* <div>
                  <SelectField
                    placeholder={"Lieu de prise en charge"}
                    handleSelect={(name, event, value) =>
                      handleSelectForProduct(name, event, value)
                    }
                    options={[
                      { label: "Devant chez moi", value: "Devant chez moi" },
                      {
                        label: "Dans la pièce de mon choix",
                        value: "Dans la pièce de mon choix",
                      },
                    ]}
                    value={state?.pickupLocation}
                    name={"pickupLocation"}
                  />
                </div> */}
                <div>
                  <InputField
                    placeHolder={"Nom du destinataire"}
                    input_value={state.reciverName ?? ""}
                    onchange={onInputChange}
                    input_name={"reciverName"}
                    input_style={{
                      background: "#F4F3F6",
                      width: "100%",
                      borderRadius: "16px",
                      border: "none",
                      fontSize: "16px",
                      float: "left",
                    }}
                  />
                </div>
                <div
                  style={{
                    width: "95%",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <FlagInputField
                    classes={classes}
                    required={false}
                    name={"phone"}
                    codeName={"phone_code"}
                    value={state?.phone || ""}
                    countryCode={state?.phone_code || "+92"}
                    handleChangeMobileCode={(dialcode, codeName) =>
                      handleChangeMobileCode(dialcode, codeName)
                    }
                    handleChangeMobile={(event) => handleChangeMobile(event)}
                    enableFlagInput={enableFlagInput}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                    gap: 10,
                  }}
                >
                  <CustomButton
                    classesNames={
                      state?.screenStep === 1
                        ? "outlined_button_disable"
                        : "outlined_button"
                    }
                    value={"Retour"}
                    style={{ width: "150px" }}
                    disable={state?.screenStep === 1 ? true : false}
                    onClick={() => {
                      setState({
                        screenStep: state?.screenStep - 1,
                      });
                    }}
                  />

                  <CustomButton
                    classesNames={"contained_button"}
                    value={"Suivant"}
                    style={{ width: "150px" }}
                    onClick={() => {
                      setState({
                        screenStep: state?.screenStep + 1,
                      });
                    }}
                  />
                </div>
              </div>
            ) : state?.screenStep === 3 ? (
              <div style={{ maxWidth: "600px" }}>
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "32px",
                    fontWeight: "700",
                  }}
                >
                  Récapitulatif
                </p>
                <div
                  style={{
                    background: defaultLabelColors.white,
                    padding: "20px 20px 20px 20px",
                    borderRadius: "12px",
                    marginTop: "40px",
                    // width: "500px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "10%",
                        borderRadius: 100,
                        background: "#f2f2f2",
                        padding: 10,
                      }}
                      className="display-flex"
                    >
                      <MapHomeIcon
                        height={30}
                        width={30}
                        fill={defaultLabelColors.main_blue}
                      />
                    </div>
                    <div style={{ width: "90%", lineHeight: "15px" }}>
                      <p
                        style={{
                          fontSize: "16px",
                          fontFamily: "Raleway-Variable",
                        }}
                      >
                        Adresse de ramassage
                      </p>
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          fontFamily: "Raleway-Variable",
                        }}
                      >
                        {state?.originAddress?.formatted_address ?? ""}
                      </p>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "10%",
                        borderRadius: 100,
                        background: "#f2f2f2",
                        padding: 10,
                      }}
                      className="display-flex"
                    >
                      <LocationPinIcon
                        height={35}
                        width={35}
                        fill={defaultLabelColors.main_orange}
                      />
                    </div>
                    <div style={{ width: "90%", lineHeight: "15px" }}>
                      <p
                        style={{
                          fontSize: "16px",
                          fontFamily: "Raleway-Variable",
                        }}
                      >
                        Adresse de livraison
                      </p>
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          fontFamily: "Raleway-Variable",
                        }}
                      >
                        {state?.destinationAddress?.formatted_address ?? ""}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    background: defaultLabelColors.white,
                    padding: "10px 20px 20px 20px",
                    borderRadius: "12px",
                    marginTop: "10px",
                    // width: "500px",
                  }}
                >
                  <p
                    style={{ fontFamily: "Raleway-Variable", fontSize: "18px" }}
                  >
                    Colis
                  </p>
                  {state?.details?.map((i: any) => {
                    return (
                      <div className="card-container">
                        <img src={i?.images[0].uri} className="card-image" />
                        <div className="card-details">
                          <div className="card-title" style={{ fontSize: 17 }}>
                            {i?.packageType ?? ""}
                          </div>
                          <div className="card-dimensions">
                            {i?.productSize[0]?.size ?? ""}
                          </div>
                        </div>
                        <div className="card-quantity">
                          x{i?.productQuantity ?? "1"}
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div
                  style={{
                    background: defaultLabelColors.white,
                    padding: "10px 20px 10px 20px",
                    borderRadius: "12px",
                    marginTop: "10px",
                    // width: "500px",
                    cursor: "pointer",
                    display: "flex",
                  }}
                  onClick={() => {
                    setState({
                      openJobTypeDialog: true,
                    });
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "18px",
                      }}
                    >
                      Type de livraison
                    </p>
                    <p
                      style={{
                        fontSize: "20px",
                        fontFamily: "Raleway-Variable",
                        fontWeight: "600",
                      }}
                    >
                      {selectedValue ?? "Express - entre 1 à 2 jours"}
                    </p>
                  </div>
                  <div className="display-flex">
                    <KeyboardArrowRight
                      style={{ fontSize: 32, fontWeight: 700 }}
                    />
                  </div>
                </div>

                <div
                  style={{
                    background: defaultLabelColors.white,
                    padding: "10px 20px 10px 20px",
                    borderRadius: "12px",
                    marginTop: "10px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "18px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    Définissez un prix de livraison
                  </p>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <InputField
                      placeHolder={"7 €"}
                      input_value={state.shipmentPrice ?? ""}
                      onchange={onInputChange}
                      input_name={"shipmentPrice"}
                      startIcon={"ex :"}
                      input_style={{
                        background: "none",
                        width: "300px",
                        borderRadius: "16px",
                        border: "none",
                        fontSize: "52px",
                        fontWeight: "700",
                        color: "grey",
                      }}
                    />
                  </div>
                  <p style={{ fontFamily: "Raleway-Italic", fontSize: "16px" }}>
                    Le prix recommandé par Frank est compris enre 5.00 € et
                    6.00€
                  </p>
                </div>
                <div
                  style={{
                    background: defaultLabelColors.white,
                    padding: "10px 20px 10px 20px",
                    borderRadius: "12px",
                    marginTop: "10px",
                    // width: "500px",
                    lineHeight: "40px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    <div style={{ flex: 1 }}>Frais de livraison</div>
                    <div>€ 7.00</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                      fontWeight: "600",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    <div style={{ flex: 1 }}>Frais de service</div>
                    <div>€ 1.50</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    <div style={{ flex: 1 }}>Total à payer</div>
                    <div>€ 7.00</div>
                  </div>
                </div>
                <div style={ {}}>
                <Grid xs={12} item >
                  <FormGroup style={{  }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => onCheckboxChange(event)}
                          name="is_accepted"
                          checked={state?.is_accepted ? true : false}
                          size="small"
                        />
                      }
                      label={
                        <span
                          style={{
                            fontSize: "16px",
                            fontFamily: "Raleway-Variable",
                          }}
                        >
                          {" "}
                          En cliquant sur Publier mon annonce, j'accepte les <a href="https://frankme.com/terms-and-condition" target="_blank" style={{color:"#000"}}>  Conditions Générales d’Utilisations</a>
                        </span>
                      }
                    />

                     
                  </FormGroup>
                </Grid>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                    gap: 10,
                  }}
                >
                  <CustomButton
                    classesNames={
                      state?.screenStep === 1
                        ? "outlined_button_disable"
                        : "outlined_button"
                    }
                    value={"Retour"}
                    style={{ width: "150px" }}
                    disable={state?.screenStep === 1 ? true : false}
                    onClick={() => {
                      setState({
                        screenStep: state?.screenStep - 1,
                      });
                    }}
                  />

                  <CustomButton
                  disable={state?.is_accepted ? false : true} 
                    classesNames={"contained_button"}
                    value={"Suivant"}
                    style={{ width: "150px" }}
                    onClick={handleCalculateShippingCharges}
                  />
                </div>
              </div>
            ) : null}
          </Grid>

          {state?.screenStep < 3 ? (
            <Grid xs={12} md={4} item style={{}}>
              {state?.screenStep === 3 ? null : (
                <div
                  style={{
                    background: defaultLabelColors.white,
                    padding: "20px 20px 20px 20px",
                    borderRadius: "12px",
                    marginTop: "40px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      background: defaultLabelColors.white,
                      marginBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Variable",
                        fontSize: "22px",
                        fontWeight: "700",
                      }}
                    >
                      Récapitulatif
                    </div>
                    <div>
                      <CartIcon />
                    </div>
                  </div>
                  <div>
                    {state?.details?.map((i: any) => {
                      return (
                        <div
                          className="card-container"
                          style={{ marginTop: "5px" }}
                        >
                          <img src={i?.images[0].uri} className="card-image" />
                          <div className="card-details">
                            <div
                              className="card-title"
                              style={{ fontSize: 17 }}
                            >
                              {i?.packageType ?? ""}
                            </div>
                            <div className="card-dimensions">
                              {i?.productSize[0].size ?? ""}
                            </div>
                          </div>
                          <div className="card-quantity">
                            x{i?.productQuantity}
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {state?.screenStep === 1 ? null : (
                    <div
                      style={{
                        background: "#F5F5F5",
                        borderRadius: 16,
                        padding: "16px",
                        marginTop: "20px",
                      }}
                    >
                      <div style={{ fontFamily: "Raleway-Variable" }}>
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontFamily: "Raleway-Variable",
                            gap: 10,
                            fontSize: 14,
                            color: "grey",
                            marginBottom: 0,
                          }}
                        >
                          <HomeIconBlue fill={"grey"} height={20} width={20} />
                          Adresse de ramassage
                        </p>

                        <p style={{ fontSize: "16px" }}>
                          {state?.originAddress?.formatted_address ??
                            "14 avenue du général de Gaule, 75008 Paris"}
                        </p>
                      </div>
                      <div style={{ fontFamily: "Raleway-Variable" }}>
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontFamily: "Raleway-Variable",
                            gap: 10,
                            fontSize: 14,
                            color: "grey",
                            marginBottom: 0,
                          }}
                        >
                          <LocationPinIcon
                            fill={"grey"}
                            height={20}
                            width={20}
                          />
                          Adresse de livraison
                        </p>

                        <p style={{ fontSize: "16px" }}>
                          {state?.destinationAddress?.formatted_address ??
                            "Destination"}
                        </p>
                        <p style={{ fontSize: "16px" }}>
                          {state?.pickupLocation?.value ?? "Devant chez moi"}
                        </p>
                      </div>

                      <div style={{ fontFamily: "Raleway-Variable" }}>
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontFamily: "Raleway-Variable",
                            gap: 10,
                            fontSize: 14,
                            color: "grey",
                            marginBottom: 0,
                          }}
                        >
                          Type de livraison
                        </p>

                        <p style={{ fontSize: "16px" }}>
                          {selectedValue ?? "Express - entre 1 à 2 jours"}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Grid>
          ) : null}
        </Grid>
      </div>

      <DraggableDialog
        maxWidth={"sm"}
        open={state?.openJobTypeDialog}
        handleClose={() => {
          setState({ openJobTypeDialog: false });
        }}
        dialog_title={`Type de livraison`}
        classes={classes}
        dialogBody={
          <DialogContent
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
            dividers
          >
            <div style={{ width: "100%" }}>
              {PACKAGE_SHIPMENT_TYPE?.map((i: any) => {
                return (
                  <>
                    <div
                      style={{
                        borderRadius: "16px",
                        background: "#F4F3F6",
                        padding: 15,
                        fontFamily: "Raleway-Variable",
                        cursor: "pointer",
                        marginTop: 5,
                        border: `2px solid ${
                          selectedValue === i?.type
                            ? defaultLabelColors.main_blue
                            : "#f3f3f3"
                        }`,
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "800",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ flex: 1 }}>{i?.type}</div>
                        <div>
                          <Radio
                            checked={selectedValue === i?.type}
                            onChange={handleChange}
                            value={i?.type}
                            name="type"
                            style={{ color: "#000" }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          fontWeight: "700",
                          fontSize: "14px",
                          marginTop: "10px",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "500",
                            fontFamily: "Raleway-Italic",
                          }}
                        >
                          {i?.data}
                        </span>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </DialogContent>
        }
      />
    </div>
  );
}

export default CheckoutPackage;
