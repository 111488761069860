/*
  Created By Muhammad Hassaan
  Date 05 April 2023
*/
import PropTypes from "prop-types";
import MUIButton from "@mui/material/Button";
export default function CustomButton(props: any) {
  const {
    classesNames,
    onClick,
    value,
    disable,
    style,
    endIcon,
    startIcon,
    variant,
    Href
  } = props;
  return (
    <MUIButton
      className={classesNames}
      disabled={disable}
      variant={variant}
      onClick={onClick}
      style={style}
      href={Href}
      endIcon={endIcon}
      startIcon={startIcon}
      sx={{
        textTransform: "none",
        borderRadius: 16,
      }}
    >
      {value}
    </MUIButton>
  );
}

CustomButton.defaultProps = {
  value: "",
  classesNames: null,
  size: "small",
  variant: "outlined",
  onClick: () => {},
  disable: false,
  
 
  sx: [
    {
      textTransform: "Capitalize",
    },
  ],
};

CustomButton.propTypes = {
  value: PropTypes.any,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["outlined", "standard", "contained"]),
  classesNames: PropTypes.any,
  onClick: PropTypes.func,
  disable: PropTypes.bool,
  fullWidth: PropTypes.bool,
  style: PropTypes.any,
  endIcon: PropTypes.any,
  startIcon: PropTypes.any,
  Href:PropTypes.string
};
