import React from 'react'
import facebookIcon from "../../assets/images/FrankIcons/Artboard_1.png";
import instaIcon from "../../assets/images/FrankIcons/Artboard_2.png";
import linkedInIcon from "../../assets/images/FrankIcons/Artboard_3.png";
import footer from "../../assets/images/FrankIcons/download-mobile-app-banner@4x.png";
import { defaultLabelColors } from '../../utils/constants';
import googlePlayBtn from "../../assets/mobileImages/french-google-play.png";
import { AppStoreFR, FrankIcon } from '../../assets/images/FrankIcons';






function CustomFooter(props:any) {
  return (
    <  >
      <div
            className="frame-175 display-flex"
            style={{ position: "relative", marginTop: "150px" }}
          >
            <div
              style={{
                borderRadius: "20px",
                zIndex: 1111,
                width: "1000px",
                position: "absolute",
                top: -140,
              }}
            >
              <img src={footer} alt="" />
              <div style={{ position: "absolute", bottom: 80, right: "-43%" }}>
                <p
                  style={{
                    fontSize: "36px",
                    fontWeight: "900",
                    fontFamily: "Raleway-Italic",
                    color: defaultLabelColors.white,
                  }}
                >
                  Téléchargez l'application <br />
                  mobile Frank
                </p>
                <p
                  style={{
                    color: defaultLabelColors.white,
                    fontSize: 24,
                    fontFamily: "Raleway-Italic",
                  }}
                >
                  Rejoignez gratuitement la communauté
                  <br /> de livraison verte
                </p>
                <div style={{ display: "flex" }}>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.frankc2c"
                    target="_blank"
                    style={{ width: 160 }}
                  >
                    <img
                      src={googlePlayBtn}
                      style={{ height: "50px", width: "160px" }}
                      alt=""
                    />{" "}
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/frank/id6502341979"
                    target="_blank"
                    style={{ width: 160, marginLeft: "10px" }}
                  >
                    {" "}
                    <AppStoreFR />
                  </a>
                </div>
              </div>
            </div>

            <div className="footer-new">
              <div className="contain">
                <div className="div-line-123"></div>

                <div className="col">
                  <h1>
                    {" "}
                    <FrankIcon fill={"#fff"} height={60} />
                  </h1>
                </div>
                <div className="col">
                  <h1>Particulier</h1>
                  <ul>
                    <li>Envoyer un colis</li>
                    <li>Transporteur</li>
                    <li>Devenir transporteur</li>

                    <li style={{ marginTop: "10px", fontWeight: "700" }}>
                      Mon compte
                    </li>
                    <li style={{ fontWeight: "700" }}>Messagerie</li>
                  </ul>
                </div>
                <div className="col">
                  <h1>Transporteur</h1>
                  <ul>
                    <li>Devenir transporteur</li>
                    <li>Voir les annonces pour</li>
                    <li> une livraison</li>
                  </ul>
                </div>
                <div className="col">
                  <h1>Déménagement </h1>
                  <ul>
                    <li>Déménageur particulier</li>
                    <li>Déménageur professionnel</li>
                  </ul>
                </div>
                <div className="col">
                  <h1>Marketplace</h1>
                  <ul></ul>
                </div>
                <div className="col">
                  <h1>Stockage colis</h1>
                  <ul>
                    <li>Trouver un point relais particulier </li>
                    <li> Proposer un espace de point relais</li>
                    <li>Consigner bagage</li>

                    <li
                      style={{
                        marginTop: "10px",
                        fontWeight: "700",
                        fontSize: "18px",
                      }}
                    >
                      Blog
                    </li>
                    <li style={{ fontWeight: "700", fontSize: "18px" }}>FAQ</li>
                  </ul>
                </div>
                <div className="col" style={{ width: "190px",height:200 }}>
                  <div
                    style={{
                      border: "1px solid #fff",
                      borderRadius: "12px",
                      marginTop: "20px",
                      padding: "20px",
                      width: "180px",
                    }}
                  >
                    <h1 style={{ padding: 0 }}>Nous Contacter:</h1>
                    <ul>
                      <li>hello@frankme.com</li>
                      <li> 04 XX XX XX XX</li>
                      <li>91 avenue de la République 75011 Paris</li>
                    </ul>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "70px",
                      gap: "10px",
                      marginTop: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <div>
                      <img src={facebookIcon} alt="" />
                    </div>
                    <div>
                      <img src={instaIcon} alt="" />
                    </div>
                    <div>
                      <img src={linkedInIcon} alt="" />
                    </div>
                  </div>
                </div>
                <div
                  className="col"
                  style={{
                    width: "450px",
                    marginLeft: "170px",
                    fontFamily: "Raleway-Italic",
                    fontSize: "14px",
                    color: "#fff",
                    marginTop: "-40px",
                  }}
                >
                  <a
                    href="https://frankme.com/privacy-policy"
                    target="_blank"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    {" "}
                    Politique de confidentialité
                  </a>{" "}
                  |{" "}
                  <a
                    style={{ textDecoration: "none", color: "#fff" }}
                    href="https://frankme.com/terms-and-condition"
                    target="_blank"
                  >
                    Termes et conditions
                  </a>
                </div>

                <div className="clearfix"></div>
              </div>
            </div>
          </div>
    </ >
  )
}

export default CustomFooter
