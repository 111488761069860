import React, { useEffect, useState } from "react";
import {
  AppStoreFR,
  ArticleImageOne,
  ArticleImageThree,
  ArticleImageTwo,
  FrankIcon,
  MapHomeIcon,
  MapPointerIcon,
  PracticeImage,
} from "../../assets/images/FrankIcons";
import playSore from "../../assets/images/FrankIcons/PlayStore-btn.png";

import facebookIcon from "../../assets/images/FrankIcons/Artboard_1.png";
import instaIcon from "../../assets/images/FrankIcons/Artboard_2.png";
import downloadappStoreBanner from "../../assets/images/FrankIcons/download-app-mobiles@4x.png";

import linkedInIcon from "../../assets/images/FrankIcons/Artboard_3.png";
import {
  Button,
  Card,
  CardContent,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  Close,
  ExpandLess,
  ExpandMore,
  PersonOutlineOutlined,
  Search,
} from "@mui/icons-material";
import drawerBoxIcon from "../../assets/mobileImages/drawer-box-icon.png";
import transporterUnColisImage from "../../assets/images/FrankIcons/transporterUnColis.png";
import commentCafonctionne from "../../assets/images/FrankIcons/comment_ca_fonctionne.png";
import { defaultLabelColors } from "../../utils/constants";
import CustomButton from "../../UiComponent/CustomButton";
import { strings } from "../../Store/Localization";
import GoogleMaps from "../../UiComponent/GoogleMaps";
import apiServices from "../../services/RequestHandler";
import CustomHeaderMobile from "../../UiComponent/CustomeHeaderMobile";

function TransporterUnColisMobile(props: any) 
{
  const { classes } = props;
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      announes:"All"
    }
  );
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState<string | null>(null); // Track the currently expanded menu

  useEffect(() => {
    handleGetAllJobs();
  }, []);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        (event.type === "keydown" &&
          (event as React.KeyboardEvent).key === "Tab") ||
        (event as React.KeyboardEvent).key === "Shift"
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const handleMenuClick = (menuId: string) => () => {
    setExpandedMenu(expandedMenu === menuId ? null : menuId);
  };

  const handleGetAllJobs = async () => {
    try {
      let body: any = {
        type: state?.announes,
      };
      if (state?.announes === "Around Me") {
        navigator.geolocation.getCurrentPosition(function (position) {
          body = {
            ...body,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        });
      }
      const resp = await apiServices.postFromApi(
        "c2c-shipments/web-all-announcements",
        body,
        ""
      );

      setState({
        allJobsData: resp?.data,
      });
      const locations = state?.allJobsData?.pickup?.map(
        (item: any) => item.location
      );
    } catch (error) {}
  };

  return (
    <div className="main-container" style={{ background: "#f9c9b3" }}>
      <div className="header-body-top">
     <CustomHeaderMobile />

        <div className="body" style={{ gap: "10px" }}>
          <span
            style={{
              fontFamily: "Raleway-Italic",
              color: "#000",
              fontSize: "28px",
              fontWeight: "800",
            }}
          >
            Transporter un colis
          </span>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div style={{ height: "100px" }}>
              <img src={transporterUnColisImage} alt="" />
            </div>
            <div>
              <p
                style={{
                  fontFamily: "Raleway-Variable",
                  color: "#000",
                  fontSize: "18px",
                  fontWeight: "700",
                }}
              >
                <span style={{ color: defaultLabelColors.main_blue }}>
                  {" "}
                  Gagnez de l'argent{" "}
                </span>{" "}
                en transportant des colis entre particuliers
              </p>
              <p style={{ fontFamily: "Raleway-Variable", fontSize: "14px" }}>
                Sélectionnez, transportez, encaissez ! Rien de plus simple
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: 1,
                fontFamily: "Raleway-Italic",
                color: "#000",
                fontSize: "24px",
                fontWeight: "800",
              }}
            >
              Annonces
            </div>

            <div
              style={{
                fontFamily: "Raleway-Italic",
                color: "#000",
                fontSize: "12x",
                fontWeight: "800",
                textAlign: "right",
              }}
            >
              60 annonces en lignes
            </div>
          </div>

          <div
            style={{
              display: "flex",
              padding: "10px",
              gap: "10px",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <CustomButton
            onClick={() => {
              setState({ announes: "All" });
            }}
              classesNames={
                state?.announes === "All"
                  ? "mobile_contained_button mobile_button_width"
                  : "mobile_outlined_button mobile_button_width"
              }
              
              value={"Tout"}
            />
            <CustomButton
            onClick={() => {
              setState({ announes: "Express" });
            }}
              classesNames={
                state?.announes === "Express"
                  ? "mobile_contained_button mobile_button_width"
                  : "mobile_outlined_button mobile_button_width"
              }
              
              value={strings?.Express_announces}
            />
            <CustomButton
            onClick={() => {
              setState({ announes: "Around Me" });
            }}
              classesNames={
                state?.announes === "Around Me"
                  ? "mobile_contained_button mobile_button_width"
                  : "mobile_outlined_button mobile_button_width"
              }
              
              value={"Autour de moi"}
            />
          </div>

          <div style={{ height: "271px" }}>
          <GoogleMaps
                    handleGetLocation={(data) => {
                      setState({
                        destinationAddress: data?.result[0],
                        recipient_digital_code: data?.result[0].zipcode,
                      });
                    }}
                    markers_data={state.allJobsData ?? []}
                  />
          </div>

          <div
            style={{
              display: "flex",
              borderRadius: "16px",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {state?.allJobsData?.map((i: any) => {
              return (
                <div
                  style={{
                    background: "#fff",
                    borderRadius: "16px",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <p
                        style={{
                          fontSize: "18px",
                          fontFamily: "Raleway-Italic",
                          color: "#000",
                          fontWeight: "700",
                        }}
                        className="title-text-announces"
                      >
                        {i?.shipment_items[0]?.prod_name ?? "Arbre a chat"}
                      </p>
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Italic",
                        fontSize: "24px",
                        fontWeight: "800",
                        color: defaultLabelColors.main_orange,
                        textAlign: "right",
                      }}
                    >
                      {i?.ship_price ?? "0.00"}€
                    </div>
                  </div>

                  <div
                    style={{
                      background: "#eef2f6",
                      borderRadius: "16px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                        borderBottom: "1px solid #3026f1",
                      }}
                    >
                      <p
                        style={{
                          borderRadius: "32px",
                          width: "30%",
                        }}
                      >
                        <MapHomeIcon fill={defaultLabelColors.main_blue} />
                      </p>
                      <p style={{ fontFamily: "Raleway-Italic", width: "70%" }}>
                        Depart
                        <br />
                        <span
                          style={{
                            fontFamily: "Raleway-Italic",
                            fontWeight: "700",
                          }}
                        >
                          {i?.pickup?.city ?? "City"},
                          {i?.pickup?.country ?? "Coutry"}
                        </span>
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <p
                        style={{
                          borderRadius: "32px",
                          width: "30%",
                        }}
                      >
                        <MapPointerIcon fill={defaultLabelColors.main_blue} />
                      </p>
                      <p style={{ fontFamily: "Raleway-Italic", width: "70%" }}>
                        Destination
                        <br />
                        <span
                          style={{
                            fontFamily: "Raleway-Italic",
                            fontWeight: "700",
                          }}
                        >
                          {" "}
                          {i?.dropoff?.city ?? "City"},
                          {i?.dropoff?.country ?? "City"}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div style={{ height: "100%", width: "100%" }}>
            <img src={commentCafonctionne} alt="" />
          </div>




        
        </div>

        <div style={{ padding: "10px 20px 10px 20px" }}>
        <div
          className="triangle"
          style={{ height: "100vh", position: "relative" }}
        >
          <div
            style={{
              paddingTop: 20,
              rowGap: 20,
              display: "flex",
              flexDirection: "column",
              zIndex: 111,
            }}
          >
            <FrankIcon fill={"#fff"} height={70} />

            <p
              className="heading-two"
              style={{
                fontSize: "28px",
                textAlign: "center",
                color: "#fff",
                padding: "0px 10px 0px 10px",
              }}
            >
              <span style={{ fontSize: "24px", fontWeight: "500" }}>
                Télécharger
              </span>
              <br />
              notre application mobile
            </p>

            <div
              className="display-flex"
              style={{ flexDirection: "column", gap: "10px" }}
            >
              <a
                href="https://apps.apple.com/app/frank/id6502341979"
                target="_blank"
              >
                {" "}
                <AppStoreFR height={60} />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.frankc2c"
                target="_blank"
                style={{ textAlign: "center", width: "190px" }}
              >
                {" "}
                <img src={playSore} height={56} width={"65px"} />
              </a>
            </div>
          </div>

          <img
            src={downloadappStoreBanner}
            style={{ position: "absolute", bottom: 0 }}
            alt=""
          />
        </div>
      </div>
      <div
        className="footer-new"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="contain" style={{ padding: "40px 0px 0px 0px" }}>
          <div className="col">
            <h1>
              {" "}
              <FrankIcon fill={"#fff"} height={80} />
            </h1>
          </div>
          <div className="col">
            <h1>Particulier</h1>
            <ul>
              <li>Envoyer un colis</li>
              <li>Transporteur</li>
              <li>Devenir transporteur</li>

              <li style={{ marginTop: "10px", fontWeight: "700" }}>
                Mon compte
              </li>
              <li style={{ fontWeight: "700" }}>Messagerie</li>
            </ul>
          </div>
          <div className="col">
            <h1>Transporteur</h1>
            <ul>
              <li>Devenir transporteur</li>
              <li>Voir les annonces pour</li>
              <li> une livraison</li>
            </ul>
          </div>
          <div className="col">
            <h1>Déménagement </h1>
            <ul>
              <li>Déménageur particulier</li>
              <li>Déménageur professionnel</li>
            </ul>
          </div>
          <div className="col">
            <h1>Marketplace</h1>
            <ul></ul>
          </div>
          <div className="col">
            <h1>Stockage colis</h1>
            <ul>
              <li>Trouver un point relais particulier </li>
              <li> Proposer un espace de point relais</li>
              <li>Consigner bagage</li>

              <li
                style={{
                  marginTop: "10px",
                  fontWeight: "700",
                  fontSize: "18px",
                }}
              >
                Blog
              </li>
              <li style={{ fontWeight: "700", fontSize: "18px" }}>FAQ</li>
            </ul>
          </div>
          <div className="col" style={{ width: "190px", height: 200 }}>
            <div
              style={{
                border: "1px solid #fff",
                borderRadius: "12px",
                marginTop: "20px",
                padding: "20px",
                width: "180px",
              }}
            >
              <h1 style={{ padding: 0 }}>Nous Contacter:</h1>
              <ul>
                <li>hello@frankme.com</li>
                <li> 04 XX XX XX XX</li>
                <li>91 avenue de la République 75011 Paris</li>
              </ul>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "70px",
                gap: "10px",
                marginTop: "10px",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <div>
                <img src={facebookIcon} alt="" />
              </div>
              <div>
                <img src={instaIcon} alt="" />
              </div>
              <div>
                <img src={linkedInIcon} alt="" />
              </div>
            </div>
          </div>
          <div
            className="col"
            style={{
              width: "100%",
              // marginLeft: "170px",
              fontFamily: "Raleway-Italic",
              fontSize: "14px",
              color: "#fff",
              // marginTop: "-30px",
              textAlign: "center",
            }}
          >
            <a
              href="https://frankme.com/privacy-policy"
              target="_blank"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              {" "}
              Politique de confidentialité
            </a>{" "}
            |{" "}
            <a
              style={{ textDecoration: "none", color: "#fff" }}
              href="https://frankme.com/terms-and-condition"
              target="_blank"
            >
              Termes et conditions
            </a>
          </div>

          <div className="clearfix"></div>
        </div>
      </div>

      </div>
    </div>
  );
}

export default TransporterUnColisMobile;
