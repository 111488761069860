import React from 'react'
import CustomHeaderMobile from '../../UiComponent/CustomeHeaderMobile'
import { defaultLabelColors } from '../../utils/constants'
import facebookIcon from "../../assets/images/FrankIcons/Artboard_1.png";
import instaIcon from "../../assets/images/FrankIcons/Artboard_2.png";
import downloadappStoreBanner from "../../assets/images/FrankIcons/download-app-mobiles@4x.png";
import CustomButton from '../../UiComponent/CustomButton';
import { strings } from '../../Store/Localization';
import { AppStoreFR, FrankIcon, PlusPackageitemIcon } from '../../assets/images/FrankIcons';
import playSore from "../../assets/images/FrankIcons/PlayStore-btn.png";
import linkedInIcon from "../../assets/images/FrankIcons/Artboard_3.png";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { spacing } from '@mui/system';
import { East, ExpandMore } from '@mui/icons-material';


function SendPackageMobileView(props:any) {
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
    }
  );
  return (

      <div className="main-container" style={{ background:"#f9c9b3"  }}>
      <CustomHeaderMobile />
      
      <Grid container spacing={2} style={{padding:'0px 20px 0px 20px'}}>
              <Grid xs={12} item>
                <p
                  className="heading-one"
                  style={{
                    fontFamily: "Raleway-Italic",
                    color: defaultLabelColors.main_blue,
                    fontSize:26
                  }}
                >
                  ENVOYER UN COLIS ENTRE PARTICULIERS
                </p>
                <p
                  className="paragraph-text-two"
                  style={{ fontSize:'18px' }}
                >
                  Vous voulez envoyer un colis sans vous ruiner? Découvrez la
                  livraison collaborative avec Frank!
                  <br />
                  Notre service innovant vous permet de faire livrer vos colis
                  par des particuliers. Un moyen économique et écologique
                  d'expédier vos paquets partout en France.
                </p>

                <p className="paragraph-text-two" style={{ fontWeight: "700" }}>
                  Déposez votre annonce
                </p>
              </Grid>
            </Grid>
<div style={{padding:'0px 20px 0px 35px',marginTop:'-10px'}}>
            <Grid
              container
              spacing={2}
              style={{
                background: "#fff",
                borderRadius: "16px",
                padding: "25px",
              }}
            >
              <Grid
                xs={12}
                md={6}
                item
                style={{
                  border: "1px dashed  #3026f1",
                  background: "#ede7f6",
                  borderRadius: "16px",
                }}
                className="display-flex"
              >
                <div
                  className="display-flex"
                  style={{ flexDirection: "column", gap: "0px" }}
                >
                  <PlusPackageitemIcon
                    height={32}
                    fill={defaultLabelColors.main_blue}
                  />
                  <p
                    className="heading-one"
                    style={{
                      fontFamily: "Raleway-Italic",
                      color: defaultLabelColors.main_blue,
                      fontSize: "24px",
                      textAlign:'center'
                    }}
                  >
                    Ajouter un colis
                  </p>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid
                xs={12}
                item
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingTop: "40px",
                }}
              >
                <CustomButton
                  style={{ fontFamily: "Raleway-Variable",width:'300px' }}
                  classesNames={"contained_button_two"}
                  value={
                    <React.Fragment>
                      <div
                        className="display-flex"
                        style={{
                          columnGap: "40px",
                          padding: "8px 0px 8px 0px",
                        }}
                      >
                        Me connecter
                        <East
                          style={{
                            color: defaultLabelColors.main_blue,
                            background: "#f9d9b6",
                            borderRadius: 100,
                            fontSize: 18,
                            padding: 2,
                          }}
                        />
                      </div>
                    </React.Fragment>
                  }
                />
              </Grid>
            </Grid>


            <div className="why-a3_mob" style={{marginTop:'30px'}}>
        <div className="menu-a4_mob">
          <span
            className="achetez-vos-produits-a5_mob"
            style={{ textAlign: "center",fontSize:32,fontFamily:'Raleway-Italic',fontWeight:800 }}
          >
            Nos actualités
          </span>
          <span className="soyez-livres-a6_mob" style={{ textAlign: "center" }}>
            Soyez livrés par nos Franks ou nos partenaires
          </span>
        </div>
        <div className="frame-a7_mob">
          <Grid container spacing={2}>
            <Grid xs={12}  item>
              <div className="actu">
                <div className="img-wrapper-blue">
                  <div className="pic-26-1"></div>
                </div>
                <div className="text-f9">
                  <div style={{ display: "flex", width: "100%" }}>
                    <div
                      style={{
                        width: "70%",
                        color: "#080626",
                        fontFamily: "Raleway-Variable",
                        fontSize: 18,
                        fontWeight: 700,
                      }}
                    >
                      Du nouveau chez Frank
                      <p
                        style={{
                          fontFamily: "Raleway-Italic",
                          fontSize: "14px",
                          color: "#000",
                          fontWeight: "400",
                          margin: 0,
                        }}
                      >
                        il y a 10 heures
                      </p>{" "}
                    </div>
                    <div style={{ width: "30%", textAlign: "right" }}>
                      <East
                        style={{
                          background: "#fff",
                          borderRadius: 100,
                          color: defaultLabelColors.main_blue,
                          padding: 2,
                          fontSize: "16px",
                        }}
                      />{" "}
                    </div>
                  </div>
                  <span
                    className="lorem-ipsum-dolor-fc"
                    style={{ marginBottom: "10px" }}
                  >
                    Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                    adipiscing odio et bibendum duis. Auctor scelerisque tellus
                    tortor euismod...
                  </span>
                </div>
              </div>
            </Grid>

            <Grid xs={12}  item>
              <div className="actu">
                <div className="img-wrapper-blue">
                  <div className="pic-26-2"></div>
                </div>
                <div className="text-f9">
                  <div style={{ display: "flex", width: "100%" }}>
                    <div
                      style={{
                        width: "70%",
                        color: "#080626",
                        fontFamily: "Raleway-Variable",
                        fontSize: 18,
                        fontWeight: 700,
                      }}
                    >
                      Du nouveau chez Frank
                      <p
                        style={{
                          fontFamily: "Raleway-Italic",
                          fontSize: "14px",
                          color: "#000",
                          fontWeight: "400",
                          margin: 0,
                        }}
                      >
                        il y a 10 heures
                      </p>{" "}
                    </div>
                    <div style={{ width: "30%", textAlign: "right" }}>
                      <East
                        style={{
                          background: "#fff",
                          borderRadius: 100,
                          color: defaultLabelColors.main_blue,
                          padding: 2,
                          fontSize: "16px",
                        }}
                      />{" "}
                    </div>
                  </div>
                  <span
                    className="lorem-ipsum-dolor-fc"
                    style={{ marginBottom: "10px" }}
                  >
                    Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                    adipiscing odio et bibendum duis. Auctor scelerisque tellus
                    tortor euismod...
                  </span>
                </div>
              </div>
            </Grid>
            <Grid xs={12}  item>
              <div className="actu">
                <div className="img-wrapper-blue">
                  <div className="pic-26-3"></div>
                </div>
                <div className="text-f9">
                  <div style={{ display: "flex", width: "100%" }}>
                    <div
                      style={{
                        width: "70%",
                        color: "#080626",
                        fontFamily: "Raleway-Variable",
                        fontSize: 18,
                        fontWeight: 700,
                      }}
                    >
                      Du nouveau chez Frank
                      <p
                        style={{
                          fontFamily: "Raleway-Italic",
                          fontSize: "14px",
                          color: "#000",
                          fontWeight: "400",
                          margin: 0,
                        }}
                      >
                        il y a 10 heures
                      </p>{" "}
                    </div>
                    <div style={{ width: "30%", textAlign: "right" }}>
                      <East
                        style={{
                          background: "#fff",
                          borderRadius: 100,
                          color: defaultLabelColors.main_blue,
                          padding: 2,
                          fontSize: "16px",
                        }}
                      />{" "}
                    </div>
                  </div>
                  <span
                    className="lorem-ipsum-dolor-fc"
                    style={{ marginBottom: "10px" }}
                  >
                    Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                    adipiscing odio et bibendum duis. Auctor scelerisque tellus
                    tortor euismod...
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Raleway-Italic",
            fontSize: "22px",
          }}
        >
          VOIR TOUS LES ARTICLES <East />
        </div>
      </div>

      <Grid container spacing={2} style={{marginTop:'30px'}}  >
        <Grid
          xs={12}
          item
          className="display-flex"
          style={{
            flexDirection: "column",
            borderRadius: "16px",
            background: defaultLabelColors.main_background,
            paddingRight:'10px'
          }}
        >
          <span
            className="span-marketplace-questions"
            style={{
              textAlign: "left",
              paddingLeft: "16px",
              paddingBottom: "30px",
              fontWeight: "900",
              fontSize: "32px",
            }}
          >
            Des questions ?
          </span>
          <div className="div-frame-116">
            <div style={{ width: "100%" }}>
              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: defaultLabelColors.main_background,
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h5 style={{ fontWeight: "500", fontSize: "14px" }}>
                    Comment fonctionne la livraison collaborative ?
                  </h5>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    width: "100%",
                    fontFamily: "Raleway-Variable",
                  }}
                >
                  Grâce à notre plateforme, un particulier peut envoyer un colis
                  qui sera livré par un autre particulier lors d’un de ses
                  trajets personnels. Au cœur de notre activité, la livraison
                  collaborative nous permet de créer une communauté d’entraide
                  en garantissant un service d’envoi peu coûteux et écologique.
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

          <div className="div-line-117-mb"  ></div>
          <div className="div-frame-116">
            <div style={{ width: "100%" }}>
              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: defaultLabelColors.main_background,
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500", fontSize: "14px" }}>
                    Comment fonctionne la livraison professionnelle ... ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <p>
                    {" "}
                    Nous couvrons l’envoi du premier au dernier kilomètre et
                    stockons vos colis dans notre entrepôt en Île-de-France. En
                    fonction de vos besoins, nous proposons plusieurs modes de
                    livraison professionnelle :
                  </p>

                  <p>
                    {" "}
                    • Pick & Pack : récupération des colis à votre adresse,
                    préparation des colis, packaging d’envoi ;{" "}
                  </p>
                  <p>
                    • Livraison totale : nous nous occupons de tout, de la
                    récupération des colis jusqu’à leur dépôt chez le client
                    final ;
                  </p>
                  <p>
                    • Stockage :nous stockons vos colis dans notre entrepôt en
                    Île-de-France, avant leur retrait.
                  </p>

                  <p>
                    {" "}
                    Pour plus d’informations, vous pouvez nous contacter en
                    envoyant un mail à l’adresse :{" "}
                    <a href="mailto:hello@frankme.com">hello@frankme.com</a>.
                  </p>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className="div-line-117-mb"></div>
          <div className="div-frame-116">
            <div style={{ width: "100%" }}>
              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: defaultLabelColors.main_background,
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500", fontSize: "14px" }}>
                    Puis-je gagner de l’argent avec Frank ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <p>
                    {" "}
                    Vous êtes transporteur ? En voiture, en train, en
                    trottinette, en péniche ou à pied, quel que soit le mode de
                    transport, vous gagnez de l’argent !{" "}
                  </p>

                  <p>
                    Vous envoyez un colis avec Frank ? Vous ne gagnez pas
                    d’argent, mais vous en économisez à coup sûr !{" "}
                  </p>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className="div-line-117-mb"></div>
          <div className="div-frame-116">
            <div style={{ width: "100%" }}>
              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: defaultLabelColors.main_background,
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500", fontSize: "14px" }}>
                    Comment fonctionne l’assurance des colis ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  Tout envoi de colis via Frank est assuré à une hauteur de 100
                  €. Pour vos colis les plus précieux, vous pouvez souscrire à
                  une assurance supplémentaire (HISCOX).
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className="div-line-117-mb"></div>
          <div className="div-frame-116">
            <div style={{ width: "100%" }}>
              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: defaultLabelColors.main_background,
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500", fontSize: "14px" }}>
                    Comment devenir transporteur ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <p>
                    {" "}
                    Pour devenir transporteur à nos côtés, rien de plus simple :{" "}
                  </p>

                  <p>
                    {" "}
                    • Vous créez un compte sur le site ou l’application de Frank
                    ;{" "}
                  </p>
                  <p>
                    {" "}
                    • Vous checkez les annonces à côté de vous, ou sur vos
                    trajets ;{" "}
                  </p>
                  <p> • Vous acceptez une offre ;</p>
                  <p>
                    {" "}
                    • Vous remettez le colis au destinataires, qui vous remet un
                    code pour valider la livraison ;{" "}
                  </p>
                  <p>
                    {" "}
                    • Vous recevez le montant de la livraison sur votre
                    porte-monnaie une fois que vous avez renseigné le code.{" "}
                  </p>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className="div-line-117-mb"></div>
          <div className="div-frame-116">
            <div style={{ width: "100%" }}>
              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: defaultLabelColors.main_background,
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500", fontSize: "14px" }}>
                    En tant que transporteur, comment transférer ...
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <p>
                    Sur l’application Frank, il y a un espace porte-monnaie, où
                    vous trouverez le montant total de vos gains. Il suffit de
                    cliquer sur la carte bancaire, puis sur “transférer sur un
                    compte bancaire” et de renseigner les informations
                    demandées. L’argent arrive sur votre compte au bout de
                    quelques jours !
                  </p>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

          <div className="div-line-117-mb"></div>
          <div className="div-frame-116">
            <div style={{ width: "100%" }}>
              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: defaultLabelColors.main_background,
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500", fontSize: "14px" }}>
                    C’est quoi, le Marché de Frank ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <p>
                    {" "}
                    Le Marché de Frank, c’est une marketplace pour particuliers
                    et pour professionnels :{" "}
                  </p>
                  <p>
                    {" "}
                    • Les professionnels peuvent y vendre autant de produits
                    qu’ils le veulent. Chaque professionnel a son propre shop au
                    nom de sa marque ;{" "}
                  </p>
                  <p>
                    • Les particuliers peuvent vendre leurs articles de seconde
                    main ou leurs créations personnelles jusqu’à une hauteur de
                    2 500 euros par mois. Au-delà de ce montant, vous êtes
                    clairement un professionnel !
                  </p>
                  Pour les vendeurs particuliers, la livraison des produits est
                  également assurée par des particuliers. Une fois qu’une
                  personne a acheté un objet, une annonce est automatiquement
                  créée dans l’espace dédié aux transporteurs. Il ne reste plus
                  qu’à attendre que l’un d’entre eux accepte la livraison ! Ce
                  système de marketplace collaborative est écologique de A à Z.
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

          <div className="div-line-117-mb"></div>
          <div className="div-frame-116">
            <div style={{ width: "100%" }}>
              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: defaultLabelColors.main_background,
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500", fontSize: "14px" }}>
                    Y a-t-il une limite de poids pour la livraison ... ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <p>
                    Il n’y a pas de limite de poids chez Frank. Pour un
                    déménagement, vous pouvez prévoir le nombre de personnes
                    dont vous avez besoin en renfort en fonction de la taille
                    des meubles.
                  </p>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

        </Grid>
      </Grid>
            </div>

        

        <div style={{ padding: "10px 20px 10px 20px" }}>
        <div
          className="triangle"
          style={{ height: "100vh", position: "relative" }}
        >
          <div
            style={{
              paddingTop: 20,
              rowGap: 20,
              display: "flex",
              flexDirection: "column",
              zIndex: 111,
            }}
          >
            <FrankIcon fill={"#fff"} height={70} />

            <p
              className="heading-two"
              style={{
                fontSize: "28px",
                textAlign: "center",
                color: "#fff",
                padding: "0px 10px 0px 10px",
              }}
            >
              <span style={{ fontSize: "24px", fontWeight: "500" }}>
                Télécharger
              </span>
              <br />
              notre application mobile
            </p>

            <div
              className="display-flex"
              style={{ flexDirection: "column", gap: "10px" }}
            >
              <a
                href="https://apps.apple.com/app/frank/id6502341979"
                target="_blank"
              >
                {" "}
                <AppStoreFR height={60} />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.frankc2c"
                target="_blank"
                style={{ textAlign: "center", width: "190px" }}
              >
                {" "}
                <img src={playSore} height={56} width={"65px"} />
              </a>
            </div>
          </div>

          <img
            src={downloadappStoreBanner}
            style={{ position: "absolute", bottom: 0 }}
            alt=""
          />
        </div>
      </div>
      <div
        className="footer-new"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="contain" style={{ padding: "40px 0px 0px 0px" }}>
          <div className="col">
            <h1>
              {" "}
              <FrankIcon fill={"#fff"} height={80} />
            </h1>
          </div>
          <div className="col">
            <h1>Particulier</h1>
            <ul>
              <li>Envoyer un colis</li>
              <li>Transporteur</li>
              <li>Devenir transporteur</li>

              <li style={{ marginTop: "10px", fontWeight: "700" }}>
                Mon compte
              </li>
              <li style={{ fontWeight: "700" }}>Messagerie</li>
            </ul>
          </div>
          <div className="col">
            <h1>Transporteur</h1>
            <ul>
              <li>Devenir transporteur</li>
              <li>Voir les annonces pour</li>
              <li> une livraison</li>
            </ul>
          </div>
          <div className="col">
            <h1>Déménagement </h1>
            <ul>
              <li>Déménageur particulier</li>
              <li>Déménageur professionnel</li>
            </ul>
          </div>
          <div className="col">
            <h1>Marketplace</h1>
            <ul></ul>
          </div>
          <div className="col">
            <h1>Stockage colis</h1>
            <ul>
              <li>Trouver un point relais particulier </li>
              <li> Proposer un espace de point relais</li>
              <li>Consigner bagage</li>

              <li
                style={{
                  marginTop: "10px",
                  fontWeight: "700",
                  fontSize: "18px",
                }}
              >
                Blog
              </li>
              <li style={{ fontWeight: "700", fontSize: "18px" }}>FAQ</li>
            </ul>
          </div>
          <div className="col" style={{ width: "190px", height: 200 }}>
            <div
              style={{
                border: "1px solid #fff",
                borderRadius: "12px",
                marginTop: "20px",
                padding: "20px",
                width: "180px",
              }}
            >
              <h1 style={{ padding: 0 }}>Nous Contacter:</h1>
              <ul>
                <li>hello@frankme.com</li>
                <li> 04 XX XX XX XX</li>
                <li>91 avenue de la République 75011 Paris</li>
              </ul>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "70px",
                gap: "10px",
                marginTop: "10px",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <div>
                <img src={facebookIcon} alt="" />
              </div>
              <div>
                <img src={instaIcon} alt="" />
              </div>
              <div>
                <img src={linkedInIcon} alt="" />
              </div>
            </div>
          </div>
          <div
            className="col"
            style={{
              width: "100%",
              // marginLeft: "170px",
              fontFamily: "Raleway-Italic",
              fontSize: "14px",
              color: "#fff",
              // marginTop: "-30px",
              textAlign: "center",
            }}
          >
            <a
              href="https://frankme.com/privacy-policy"
              target="_blank"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              {" "}
              Politique de confidentialité
            </a>{" "}
            |{" "}
            <a
              style={{ textDecoration: "none", color: "#fff" }}
              href="https://frankme.com/terms-and-condition"
              target="_blank"
            >
              Termes et conditions
            </a>
          </div>

          <div className="clearfix"></div>
        </div>
      </div>

      </div>
 

  )
}

export default SendPackageMobileView