export const current_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

 

const numToWordObj = {
  0: "Zero",
  1: "One",
  2: "Two",
  3: "Three",
  4: "Four",
  5: "Five",
  6: "Six",
  7: "Seven",
  8: "Eight",
  9: "Nine",
  10: "Ten",
  11: "Eleven",
  12: "Twelve",
  13: "Thirteen",
  14: "Fourteen",
  15: "Fifteen",
  16: "Sixteen",
  17: "Seventeen",
  18: "Eighteen",
  19: "Nineteen",
  20: "Twenty",
  30: "Thirty",
  40: "Forty",
  50: "Fifty",
  60: "Sixty",
  70: "Seventy",
  80: "Eighty",
  90: "Ninety",
} as any;

const placement = {
  100: " Hundred",
  1000: " Thousand",
  1000000: " Million",
  1000000000000: " Trillion",
};

export const convertNumToWord = (num: any): any => {
  const limiter = (val: any) => (num < val) as any;
  const limiterIndex = Object.keys(placement).findIndex(limiter) as any;
  const limiterKey = Object.keys(placement)[limiterIndex] as any;
  const limiterVal = Object.values(placement)[limiterIndex - 1] as any;
  const limiterMod = Object.keys(placement)[limiterIndex - 1] as any;

  if (numToWordObj[num]) {
    return numToWordObj[num];
  }

  if (num < 100) {
    const whole = Math.floor(num / 10) * 10;
    const part = num % 10;
    return numToWordObj[whole] + " " + numToWordObj[part];
  }

  if (num < limiterKey) {
    const whole = Math.floor(num / limiterMod);
    const part = num % limiterMod;
    if (part === 0) {
      return convertNumToWord(whole) + limiterVal;
    }
    return (
      convertNumToWord(whole) + limiterVal + " and " + convertNumToWord(part)
    );
  }
};

export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => resolve(null);
  });

export const calculateDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): string => {
  const R = 6371; // Radius of the earth in kilometers
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return Number(distance).toFixed(2);
};
const deg2rad = (deg: number): number => {
  return deg * (Math.PI / 180);
};

export const numberFormatWithoutCurrency = (value: number, lang = 'en-FR') =>
  Intl.NumberFormat(lang, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value);

export const numberFormatWithoutCurrencyFixed = (
  value: number,
  lang = 'en-FR',
) => Intl.NumberFormat(lang).format(value);

export const numberFormatWithoutCurrencyFixedOne = (
  value: number,
  lang = 'en-FR',
) =>
  Intl.NumberFormat(lang, {
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
  }).format(value);

