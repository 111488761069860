import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import React from "react";
import Header from "../../UiComponent/Header";
import { defaultLabelColors, spacing } from "../../utils/constants";
import storyBackground from "../../assets/images/FrankIcons/dotted2x.png";
import facebookIcon from "../../assets/images/FrankIcons/Artboard_1.png";
import instaIcon from "../../assets/images/FrankIcons/Artboard_2.png";
import linkedInIcon from "../../assets/images/FrankIcons/Artboard_3.png";

import {
  AppStoreFR,
  BigPackageIcon,
  FrankIcon,
  MediumPackageIcon,
  PlusPackageitemIcon,
} from "../../assets/images/FrankIcons";
import CustomButton from "../../UiComponent/CustomButton";
import { ArrowForwardIos, East, ExpandMore } from "@mui/icons-material";
import sendPackageImage from "../../assets/images/FrankIcons/send-parcel-bottom-banner.png";
import footer from "../../assets/images/FrankIcons/download-mobile-app-banner@4x.png";
import googlePlayBtn from "../../assets/mobileImages/french-google-play.png";
import CustomFooter from "../../UiComponent/CustomFooter";
import SendPackageMobileView from "./SendPackageMobileView";
import { useNavigate } from "react-router-dom";
import NewHeader from "../../UiComponent/NewHeader";

function SendPackage(props: any) {
  const navigate = useNavigate();
  return (
    <>
      <div className="web-screen">
        <div style={{ background: "#f9c7b8" }}>
          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{
              background: "#F4C9B3"
              ,paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop:'30px'
            }}
          >
            <Grid xs={12} item style={{ zIndex: 99 }}>
              <NewHeader />
            </Grid>
          </Grid>
          <div style={{ padding: "0px 140px 0px 140px" }}>
            <Grid container spacing={spacing.spacing2}>
              <Grid xs={12} item>
                <p
                  className="heading-one"
                  style={{
                    fontFamily: "Raleway-Italic",
                    color: defaultLabelColors.main_blue,
                    fontSize: 40,
                  }}
                >
                  ENVOYER UN COLIS ENTRE PARTICULIERS
                </p>
                <p
                  className="paragraph-text-two"
                  style={{ marginTop: "-30px", fontSize: "20px" }}
                >
                  Vous voulez envoyer un colis sans vous ruiner ? Découvrez la
                  livraison collaborative avec Frank!
                  <br />
                  Notre service innovant vous permet de faire livrer vos colis
                  par des particuliers. Un moyen économique et écologique
                  d'expédier vos paquets partout en France.
                </p>

                <p className="paragraph-text-two" style={{ fontWeight: "700" }}>
                  Déposez votre annonce
                </p>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={spacing.spacing2}
              style={{
                background: "#fff",
                borderRadius: "16px",
                padding: "10px",
              }}
            >
              <Grid
                xs={12}
                md={6}
                item
                style={{
                  border: "1px dashed  #3026f1",
                  background: "#ede7f6",
                  padding: "30px",
                  borderRadius: "16px",
                }}
                className="display-flex"
              >
                <div
                  className="display-flex"
                  style={{ flexDirection: "column", gap: "0px" }}
                >
                  <PlusPackageitemIcon
                    height={32}
                    fill={defaultLabelColors.main_blue}
                  />
                  <p
                    className="heading-one"
                    style={{
                      fontFamily: "Raleway-Italic",
                      color: defaultLabelColors.main_blue,
                      fontSize: "24px",
                      marginTop: "-1px",
                    }}
                  >
                    Ajouter un colis
                  </p>
                </div>
              </Grid>
              <Grid xs={12} md={6} item></Grid>
            </Grid>
            <Grid container spacing={spacing.spacing2}>
              <Grid
                xs={12}
                item
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingTop: "40px",
                }}
              >
                <CustomButton
                  style={{ fontFamily: "Raleway-Variable" }}
                  classesNames={"contained_button_two"}
                  onClick={() => {
                    navigate("/send-parcel");
                  }}
                  value={
                    <React.Fragment>
                      <div
                        className="display-flex"
                        style={{
                          columnGap: "20px",
                          padding: "4px 0px 4px 0px",
                        }}
                      >
                        Suivant
                        <East
                          style={{
                            color: defaultLabelColors.main_blue,
                            background: "#f9d9b6",
                            borderRadius: 100,
                            fontSize: 18,
                            padding: 5,
                          }}
                        />
                      </div>
                    </React.Fragment>
                  }
                />
              </Grid>
            </Grid>

            <Grid
              spacing={spacing.spacing2}
              style={{ paddingTop: "100px", paddingBottom: "100px" }}
            >
              <Grid xs={12} item>
                <div
                  className="triangle"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "20px",
                    paddingRight: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      fontFamily: "Raleway-Italic",
                      fontWeight: "800",
                      color: "#fff",
                      fontSize: "32px",
                      width: "20%",
                    }}
                  >
                    COMMENT
                    <br /> ÇA FONCTIONNE?
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "22%",
                      height: 150,
                      borderRadius: "16px",
                      justifyContent: "center",

                      alignItems: "flex-start",
                      gap: "10px",
                      flexDirection: "column",
                    }}
                    className="mstpone_1"
                  >
                    <div>
                      <span
                        style={{
                          fontFamily: "Raleway-Italic",
                          fontWeight: "900",
                          color: "#fff",
                          background: defaultLabelColors.main_orange,
                          borderRadius: 100,
                          fontSize: "25px",
                          padding: "5px 15px 10px 15px",
                          textAlign: "left",
                          marginLeft: 20,
                        }}
                      >
                        1
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: "18px",
                        fontFamily: "Raleway-Variable",
                        color: defaultLabelColors.main_blue,
                        fontWeight: "800",
                        textAlign: "left",
                        padding: "0px 5px 0px 5px",
                        marginLeft: 20,
                      }}
                    >
                      Renseignez votre <br /> trajet
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "22%",
                      height: 150,
                      borderRadius: "16px",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      gap: "10px",
                      flexDirection: "column",
                    }}
                    className="mstpone_1"
                  >
                    <div>
                      <span
                        style={{
                          fontFamily: "Raleway-Italic",
                          fontWeight: "900",
                          color: "#fff",
                          background: defaultLabelColors.main_blue,
                          borderRadius: 100,
                          fontSize: "25px",
                          padding: "5px 15px 10px 15px",
                          textAlign: "left",
                          marginLeft: "20px",
                        }}
                      >
                        2
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: "18px",
                        fontFamily: "Raleway-Variable",
                        color: defaultLabelColors.main_blue,
                        fontWeight: "800",
                        textAlign: "left",
                        padding: "0px 5px 0px 5px",
                        marginLeft: 20,
                      }}
                    >
                      Choisissez la taille <br /> du colis à transporter
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "22%",
                      height: 150,
                      borderRadius: "16px",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      gap: "10px",
                      flexDirection: "column",
                    }}
                    className="mstpone_1"
                  >
                    <div>
                      <span
                        style={{
                          fontFamily: "Raleway-Italic",
                          fontWeight: "900",
                          color: "#fff",
                          background: defaultLabelColors.main_orange,
                          borderRadius: 100,
                          fontSize: "25px",
                          padding: "5px 15px 10px 15px",
                          // textAlign: "center",
                          marginLeft: 20,
                        }}
                      >
                        3
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: "18px",
                        fontFamily: "Raleway-Variable",
                        color: defaultLabelColors.main_blue,
                        fontWeight: "800",
                        textAlign: "left",
                        padding: "0px 5px 0px 5px",
                        marginLeft: 20,
                      }}
                    >
                      Validez votre <br />
                      choix
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ padding: "0px 140px 0px 140px" }}>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                textAlign: "left",
                fontSize: "42px",
                fontFamily: "Raleway-Italic",
                fontWeight: "800",
                padding: "40px",
              }}
            >
              Nos actualités
            </div>

            <div>
              <ul className="cards">
                <li className="cards__item">
                  <div className="card">
                    <div className="card__image card__image--river"></div>
                    <div className="card__content">
                      <div style={{ display: "flex" }}>
                        <div className="card__title" style={{ flex: 1 }}>
                          Du nouveau chez Frank
                          <p
                            style={{
                              fontFamily: "Raleway-Italic",
                              fontSize: "14px",
                              color: "#000",
                              fontWeight: "400",
                              marginTop: 5,
                            }}
                          >
                            il y a 10 heures
                          </p>
                        </div>
                        <div>
                          <East
                            style={{
                              background: "#fff",
                              borderRadius: 100,
                              color: defaultLabelColors.main_blue,
                              padding: 5,
                              fontSize: "16px",
                            }}
                          />{" "}
                        </div>
                      </div>
                      <p className="card__text">
                        Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                        adipiscing odio et bibendum duis. Auctor scelerisque
                        tellus tortor euismod...
                      </p>
                    </div>
                  </div>
                </li>
                <li className="cards__item">
                  <div className="card">
                    <div className="card__image card__image--record"></div>
                    <div className="card__content">
                      <div style={{ display: "flex" }}>
                        <div className="card__title" style={{ flex: 1 }}>
                          Du nouveau chez Frank
                          <p
                            style={{
                              fontFamily: "Raleway-Italic",
                              fontSize: "14px",
                              color: "#000",
                              fontWeight: "400",
                              marginTop: 5,
                            }}
                          >
                            il y a 10 heures
                          </p>
                        </div>
                        <div>
                          <East
                            style={{
                              background: "#fff",
                              borderRadius: 100,
                              color: defaultLabelColors.main_blue,
                              padding: 5,
                              fontSize: "16px",
                            }}
                          />{" "}
                        </div>
                      </div>
                      <p className="card__text">
                        Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                        adipiscing odio et bibendum duis. Auctor scelerisque
                        tellus tortor euismod...
                      </p>
                    </div>
                  </div>
                </li>
                <li className="cards__item">
                  <div className="card">
                    <div className="card__image card__image--flowers"></div>
                    <div className="card__content">
                      <div style={{ display: "flex" }}>
                        <div className="card__title" style={{ flex: 1 }}>
                          Du nouveau chez Frank
                          <p
                            style={{
                              fontFamily: "Raleway-Italic",
                              fontSize: "14px",
                              color: "#000",
                              fontWeight: "400",
                              marginTop: 5,
                            }}
                          >
                            il y a 10 heures
                          </p>
                        </div>
                        <div>
                          <East
                            style={{
                              background: "#fff",
                              borderRadius: 100,
                              color: defaultLabelColors.main_blue,
                              padding: 5,
                              fontSize: "16px",
                            }}
                          />{" "}
                        </div>
                      </div>
                      <p className="card__text">
                        Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                        adipiscing odio et bibendum duis. Auctor scelerisque
                        tellus tortor euismod...
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          
          </Grid>

          <Grid container>
            <Grid xs={12} item>
              <div
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  marginTop: "40px",
                  fontSize: "26px",
                  fontFamily: "Raleway-Italic",
                  fontWeight: "600",
                  color: "#000",
                }}
                className="display-flex"
              >
                VOIR TOUS LES ARTICLES &nbsp; <East />
              </div>

              <div
              style={{
                width: "100%",
                marginTop: "30px",
                height: 1,
                background: "grey",
              }}
            ></div>
            </Grid>
          </Grid>

          <Grid container spacing={2} marginTop={5}>
            <Grid
              xs={12}
              item
              className="display-flex"
              style={{
                flexDirection: "column",
                background: "#F5C9B3",
                padding: "10px",
                borderRadius: "18px",
              }}
            >
              <div
                style={{
                  fontSize: "36px",
                  fontWeight: "900",
                  fontFamily: "Raleway-Italic",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  textAlign: "left",
                  width: "100%",
                  paddingLeft: "16px",
                }}
              >
                {" "}
                En savoir plus sur l'envoi de colis entre particuliers
              </div>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Quels types de colis puis-je envoyer avec Frank?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <p></p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Où faut-il déposer mon colis?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <p></p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Existe-t-il un poids maximum pour l'envoi d'un
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <p></p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Puis-je envoyer plusieurs colis en même temps?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}></AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Quelle assurance est disponible pour mon colis?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <p></p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Comment est fixé le prix pour l'envoi de mon
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <p></p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Quelles sont les zones de livraisons possibles pour l'envoi
                    de mon colis ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <p></p>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </div>

        <div
          className="main-container"
          style={{ background: "#fff", marginTop: "100px" }}
        >
          <CustomFooter />
        </div>
      </div>
      <div className="mobile-screen" style={{ width: "100%" }}>
        <SendPackageMobileView />
      </div>
    </>
  );
}

export default SendPackage;
