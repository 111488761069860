import { Delete, Edit, EditNotifications, Image } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import PropTypes from "prop-types";
import React from 'react'
import { DeleteGreyIcon, EditGreyIcon, ImageGreyIcon } from '../../assets/images/FrankIcons';

function CustomAnnouceCard(props:any) {
    const {classes,productTitle,productImage,productheight,productLength,productWidth,imageQuantity,handleEdit,shipmentQuantity,productSize,} = props;
  return (
    <Box sx={styles.card}>
    <Box sx={styles.imageContainer}>
      <img src={productImage ?? "https://via.placeholder.com/100"} alt="Table en bois clair" style={styles.image} />
    </Box>
    <Box sx={styles.detailsContainer}>
      <Typography sx={styles.title}>{productTitle ?? "Table en bois clair"}</Typography>
      <Typography sx={styles.dimensions}>{ productLength ?? 210} x {productWidth ?? 120} x { productheight ?? 40}</Typography>
      <Box sx={styles.iconTextContainer}>
        <ImageGreyIcon  />
        <Typography sx={styles.imageCount}>x { imageQuantity ?? 3}</Typography>
      </Box>
    </Box>
    <Box sx={styles.rightContainer}>
      <Typography sx={styles.quantity}>x{ shipmentQuantity ?? 1}</Typography>
      <Typography sx={styles.size}>{productSize ?? "Extra large"}</Typography>
    </Box>
    <Box sx={styles.actionIcons}>
      <IconButton onClick={handleEdit} sx={styles.iconButton}>
        <EditGreyIcon   />
      </IconButton>
      <IconButton sx={styles.iconButton}>
        <DeleteGreyIcon   />
      </IconButton>
    </Box>
  </Box>
  )
}
const styles = {
    card: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#f5f5f5',
      borderRadius: '16px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      height:"100%"
    },
    imageContainer: {
      flex: '0 0 100px',
      marginRight: '16px',
    },
    image: {
      width: '100%',
      borderRadius: '8px',
    },
    detailsContainer: {
      flex: '1 1 auto',
    },
    title: {
      fontWeight: 'bold',
      color: '#20202d',
      fontSize: '18px',
      fontFamily:"Raleway-Variable"
    },
    dimensions: {
      color: '#888',
      fontSize: '14px',
      fontFamily:"Raleway-Variable"

    },
    iconTextContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '8px',
    },
    icon: {
      color: '#888',
      marginRight: '4px',
    },
    imageCount: {
      color: '#888',
      fontFamily:"Raleway-Variable",
      fontSize: '16px',

    },
    rightContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      marginLeft: 'auto',
      marginRight: '16px',
    },
    quantity: {
      fontWeight: 'bold',
      color: '#20202d',
      fontSize: '16px',
      fontFamily:"Raleway-Variable",

    },
    size: {
      color: '#20202d',
      fontSize: '14px',
      textTransform:"Capitalize",
      fontFamily:"Raleway-Variable",
      fontWeight:600

    },
    actionIcons: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    iconButton: {
      padding: '8px',
    },
    actionIcon: {
      color: '#888',
    },
  };
  CustomAnnouceCard.defaultProps = {
    productTitle: "",
    productImage: "",
    productheight: "",
    productLength: "",
    productWidth: "",
    imageQuantity: "",
    shipmentQuantity: "1",
    productSize: "",
    handleEdit:{}
  };
  
  CustomAnnouceCard.propTypes = {
    productTitle: PropTypes.any,
    productImage: PropTypes.any,
    productheight: PropTypes.any,
    productLength: PropTypes.any,
    productWidth: PropTypes.any,
    imageQuantity: PropTypes.any,
    shipmentQuantity: PropTypes.any,
    productSize: PropTypes.any,
    handleEdit:PropTypes.func

  };
export default CustomAnnouceCard
